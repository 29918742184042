import { JackpotPriceNumbers } from "../common/JackpotPriceNumbers";
import { compactAddress } from "../utils/formatter";

type Props = {
  players: {
    address: string;
    number: number;
  }[];
  highlightCount: number;
  prize: number;
  matchType: string;
};

export const WinnerTable = ({ players, highlightCount, matchType, prize }: Props) => {
  return (
    <div className="w-full px-2 font-roboto-condensed">
      <div className="grid grid-cols-12 px-2 mb-2 text-white/60">
        <div className="col-span-2">Winner</div>
        <div className="col-span-5">Numbers</div>
        <div className="col-span-3 text-center">Matches</div>
        <div className="col-span-2 text-right">Prize</div>
      </div>
      {players.map((player, index) => (
        <div
          key={index}
          className="grid grid-cols-12 px-3 py-2 mb-2 border border-[#353734] bg-[#04132c] rounded-xl text-sm"
        >
          <div className="flex items-center col-span-2 font-bold">
            {compactAddress(player.address, 3)}
          </div>

          <HighlightNumberCircleCards highlightCount={highlightCount} number={player.number} />

          <div className="flex items-center justify-center col-span-3">{matchType}</div>

          <div className="flex items-center justify-end col-span-2 font-bold text-right text-gradient gold">
            ${prize.toLocaleString("en-US", { minimumFractionDigits: 2 })}
          </div>
        </div>
      ))}
    </div>
  );
};

const HighlightNumberCircleCards = ({
  highlightCount,
  number,
}: {
  highlightCount: number;
  number: number;
}) => {
  return (
    <div className="col-span-5">
      <JackpotPriceNumbers
        className="inline-block lg:mx-1 w-6 text-sm aspect-square border-[2px]"
        number={number}
        highlightCount={highlightCount}
      />
    </div>
  );
};
