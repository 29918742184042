import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import React, { createContext, useContext } from "react";
import { WagmiConfig } from "wagmi";
import { config } from "../config";
import { wagmiClient } from "../utils/wagmi";
import { EthereumAvatar } from "./ethereum-avatar/EthereumAvatar";

type Props = {
  children: React.ReactNode;
};

const ChainContext = createContext(config.chain);
export const useChainContext = () => useContext(ChainContext);

export function WagmiConfigProvider({ children }: Props) {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={[config.chain]}
        modalSize="compact"
        avatar={EthereumAvatar}
        initialChain={config.chain}
      >
        <ChainContext.Provider value={config.chain}>{children}</ChainContext.Provider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}
