import { BigNumberish } from "ethers";
import { useJackpotRead } from "./useJackpotRead";

export const useRound = (roundId?: BigNumberish) => {
  const { data: round } = useJackpotRead({
    functionName: "rounds",
    args: roundId,
    enabled: !!roundId,
  });

  return round;
};
