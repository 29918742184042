import clsx from "clsx";
import { useMemo } from "react";
import { Button } from "./Button";
import { Modal } from "./Modal";

type WariningProps = {
  isOpen: boolean;
  closeModal: any;
  gold?: boolean;
  numbers: (number | string)[];
};

export function NumberLimitModal({ isOpen, numbers, closeModal, gold }: WariningProps) {
  const paddedNumbers = useMemo(
    () => numbers.map((n) => n.toString().padStart(5, "0")).join(", "),
    [numbers]
  );
  return (
    <Modal active={isOpen} onDismiss={closeModal}>
      <div
        className={clsx(
          "modal-content p-5 max-w-[25rem] rounded-3xl",
          gold
            ? "card-border-gradient-gold-bg-dark-blue-gray"
            : "card-border-gradient-blue-to-yellow-bg-dark-blue-gray"
        )}
      >
        <div className="relative">
          <button onClick={closeModal}>
            <img
              src={require("../assets/icon/close.png")}
              alt=""
              className="absolute top-0 w-6 h-6 right-2"
            />
          </button>
        </div>
        <div className="text-4xl font-bold text-center uppercase text-gradient gold">
          Just miss it!
        </div>
        <div className="my-5 text-center">
          Your select number{numbers.length !== 1 && "s"}
          <span className="font-bold"> {paddedNumbers}</span> {numbers.length === 1 ? "is" : "are"}{" "}
          sold out and will be removed from your selection.
        </div>
        <div className="text-center">
          <Button
            className="px-20 uppercase"
            preset={gold ? "goldMax" : "gold"}
            buttonSize="md"
            onClick={closeModal}
          >
            Continue to buy
          </Button>
        </div>
      </div>
    </Modal>
  );
}
