import { BigNumber } from "ethers";
import { useMemo } from "react";

export const useAnswerPriceToAssetPrices = (answer?: BigNumber) => {
  const prices = useMemo(() => {
    const assetPrices = {
      bnb: 0,
      bnbDecimal: 0,
      eth: 0,
      ethDecimal: 0,
      btc: 0,
      btcDecimal: 0,
    }

    if (answer) {
      const answerString = answer.toString()

      const btcPrice = Number(answerString.slice(answerString.length - 7, answerString.length))
      const ethPrice = Number(answerString.slice(answerString.length - 13, answerString.length - 7))
      const bnbPrice = Number(answerString.slice(0, answerString.length - 13))

      assetPrices.btc = Math.floor(btcPrice / 100)
      assetPrices.btcDecimal = btcPrice % 100
      
      assetPrices.eth = Math.floor(ethPrice / 100)
      assetPrices.ethDecimal = ethPrice % 100
      
      assetPrices.bnb = Math.floor(bnbPrice / 10)
      assetPrices.bnbDecimal = bnbPrice % 10
    }

    return assetPrices
  }, [answer])

  return prices
};
