import create from "zustand";
import { JackpotBuyer } from "../hooks/contracts/jackpot";

type JackpotStore = {
  isCalculating: boolean;
  isClosed: boolean;
  roundBuyers: JackpotBuyer[];
  setIsCalculating(isCalculating: boolean): void;
  setIsClosed(isClosed: boolean): void;
  setRoundBuyers(buyers: JackpotBuyer[]): void;
  getRoundNumbers(): number[];
};

export const useJackpotStore = create<JackpotStore>((set, get) => ({
  isCalculating: false,
  isClosed: false,
  roundBuyers: [],
  setIsCalculating: (isCalculating) => set({ isCalculating }),
  setIsClosed: (isClosed) => set({ isClosed }),
  setRoundBuyers: (roundBuyers) => set({ roundBuyers }),
  getRoundNumbers: () => {
    let roundNumbers: number[] = [];

    for (const buyer of get().roundBuyers) {
      roundNumbers = roundNumbers.concat(buyer.number);
    }

    return roundNumbers;
  },
}));
