import { BigNumberish } from "ethers";
import { useMemo } from "react";
import { useRequest } from "../../useRequest";
import { useJackpotTicket } from "../../../context/JackpotTicketContext";

export type JackpotBuyerResponse = {
  buyers: JackpotBuyer[];
};

export type JackpotBuyer = {
  roundId: number;
  user: string;
  number: number[];
  transactionAt: string;
};

export function useRoundBuyers(roundId?: BigNumberish, refreshInterval?: number) {
  const jackpotTicket = useJackpotTicket();
  const { data, ...rest } = useRequest<JackpotBuyerResponse>({
    path: `/jackpot/round/${roundId}/buyers?contract=${jackpotTicket.contractAddress}`,
    enabled: !!roundId,
    config: {
      refreshInterval,
    },
  });

  const buyers = useMemo(() => data?.buyers, [data]);

  return { buyers, data, ...rest };
}
