import { useJackpotRead } from "./useJackpotRead";
import { useJackpotWatchEvent } from "./useJackpotWatchEvent";

export const useCurrentRound = () => {
  const { data: currentRoundId, contractRead } = useJackpotRead({
    functionName: "currentRoundId",
  });

  const { data: round } = useJackpotRead({
    functionName: "rounds",
    args: currentRoundId,
    enabled: currentRoundId !== undefined,
  });

  useJackpotWatchEvent(() => {
    contractRead.refetch();
  });

  return round;
};
