import { useEffect, useState } from "react";
import { CurrentRound } from "../CurrentRound";
import { useCurrentRoundState } from "./CurrentRoundProvider";
import { RoundTab } from "./RoundTab";
import { TopTabgroup } from "../../common/TopTabGroup";

const tabs = ["1 DIGIT", "2 DIGITS"];

export const PlayRound = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { setDigits } = useCurrentRoundState();

  useEffect(() => {
    setDigits?.(activeTab + 1);
  }, [activeTab]);

  return (
    <div className="flex flex-col">
      <TopTabgroup tabs={tabs} activeTab={activeTab} onTabChange={setActiveTab} />
      <CurrentRound />
    </div>
  );
};
