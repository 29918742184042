import clsx from "clsx";
import { useCallback, useMemo } from "react";
import { Button } from "../common/Button";
import { useNotificationContext } from "../common/NotificationProvider";
import { config } from "../config";
import { useJackpotStore } from "../store/jackpot";
import { useNumberStore } from "../store/number-store";

type ConfirmSwipeDigitModalProps = {
  isOpen: string;
  closeModal: any;
  swipeAtIndex: number;
  onSwiped: () => void;
  gold?: boolean;
};

export function ConfirmSwipeDigitModal({
  isOpen,
  closeModal,
  swipeAtIndex,
  onSwiped,
  gold,
}: ConfirmSwipeDigitModalProps) {
  const { addNumbers, currentNumber, numbers: buyNumbers } = useNumberStore();
  const { pushNotification } = useNotificationContext();
  const roundNumbers = useJackpotStore((state) => state.roundBuyers);

  const numberSlices = useMemo(() => {
    const firstHalf = currentNumber?.toString().slice(0, swipeAtIndex);
    const secondHalf = currentNumber?.toString().slice(swipeAtIndex + 1);
    return { firstHalf, secondHalf };
  }, [currentNumber, swipeAtIndex]);

  const numbers = useMemo(() => {
    return [...Array(10)]
      .map((_, index) => {
        const newNumber = Number(
          numberSlices.firstHalf + index.toString() + numberSlices.secondHalf
        );
        if (Number.isNaN(newNumber)) return undefined;

        const hasRoundNumber = roundNumbers?.some((number) => number.number.includes(newNumber));
        if (hasRoundNumber) return undefined;

        const hasBuyNumber = buyNumbers?.some((number) => number.number === newNumber);
        return hasBuyNumber ? undefined : newNumber;
      })
      .filter((num) => num !== undefined) as number[];
  }, [numberSlices, roundNumbers, buyNumbers]);

  const swipeNumbers = useCallback(() => {
    if (!currentNumber) return;
    addNumbers(numbers, config.maxNumberPerTx("jackpot"));

    pushNotification({
      type: "swipe",
      number: `${numberSlices.firstHalf}0${numberSlices.secondHalf} -
        ${numberSlices.firstHalf}9${numberSlices.secondHalf}`,
      key: Math.random().toString(),
    });

    onSwiped();
  }, [currentNumber, numbers, addNumbers, pushNotification, numberSlices, onSwiped]);

  return (
    <div className={`modal ${isOpen}`}>
      <div className="modal-background"></div>
      <div className="modal-card w-[300px]">
        <header
          className={clsx(
            "justify-between border-b-0 modal-card-head rounded-t-2xl",
            gold ? "bg-gradient-gold-max" : "bg-gradient-blue"
          )}
        >
          <div className="flex items-center">
            <img
              className="h-4 mr-2"
              src={gold ? require("../assets/union-white.png") : require("../assets/union.png")}
              alt=""
            />
            <p className="text-lg font-bold text-white uppercase modal-card-title">Swipe Digit</p>
          </div>
          <button onClick={closeModal}>
            <img
              src={require("../assets/icon/close.png")}
              alt=""
              className="w-5 h-5 lg:w-5 lg:h-5"
            />
          </button>
        </header>
        <section className="text-center modal-card-body bg-dark-blue-gray rounded-b-2xl">
          <p className="my-2 text-lg font-bold uppercase text-gradient gold">
            Add {numbers.length} numbers?
          </p>
          <div className="px-4 font-semibold text-white">
            {numbers.map((number) => number.toString().padStart(5, "0")).join(", ")}
          </div>
          <Button
            className="px-16 mt-8 uppercase"
            preset={gold ? "goldMax" : "gold"}
            buttonSize="sm"
            onClick={() => {
              swipeNumbers();
              closeModal();
            }}
          >
            ADD
          </Button>
        </section>
      </div>
    </div>
  );
}
