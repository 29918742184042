import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { readContract } from "wagmi/actions";
import { useJackpotTicket } from "../../../context/JackpotTicketContext";
import Jackpot from "../../../contracts/Jackpot.json";
import { PlayInfo, useJackpotRead } from "./useJackpotRead";
import { useJackpotWatchEvent } from "./useJackpotWatchEvent";

export const usePlayInfos = (account?: string) => {
  const [playInfos, setPlayInfos] = useState<PlayInfo[][]>();
  const [pending, setPending] = useState(false);
  const jackpotTicket = useJackpotTicket();

  const { data: positions = [], contractRead: positionRead } = useJackpotRead({
    functionName: "getPositions",
    args: account ?? "",
    enabled: !!account,
  });

  const requetsIdRef = useRef(1);

  const roundIds = useMemo(
    () => Array.from(new Set(positions.map((p) => p.roundId.toNumber()))),
    [positions]
  );

  const fetchPlayInfos = useCallback(async () => {
    if (!account || !roundIds) return;

    setPending(true);

    requetsIdRef.current++;
    getPlayInfosByRoundIds(
      requetsIdRef,
      requetsIdRef.current,
      account,
      roundIds,
      jackpotTicket.contractAddress
    ).then((playInfos) => {
      if (playInfos) {
        setPlayInfos(playInfos);
        setPending(false);
      }
    });
  }, [roundIds, account, jackpotTicket.contractAddress]);

  useEffect(() => {
    fetchPlayInfos();
  }, [roundIds, account, fetchPlayInfos]);

  useJackpotWatchEvent(() => {
    positionRead.refetch();
  });

  return { playInfos, pending, refetch: fetchPlayInfos, roundIds, positions };
};

async function getPlayInfosByRoundIds(
  requetsIdRef: MutableRefObject<number>,
  requestId: number,
  account?: string,
  roundIds?: number[],
  address?: string
) {
  if (!account || !roundIds?.length) return [];

  const playInfos: PlayInfo[][] = [[]];
  const limit = 1000;
  let currentPlayFetch: { roundIdIndex: 0; cursor: 0 } | null = { roundIdIndex: 0, cursor: 0 };

  while (currentPlayFetch) {
    if (requetsIdRef.current !== requestId) {
      return null;
    }

    const result = await readContract<any, PlayInfo[]>({
      addressOrName: address ?? "",
      contractInterface: Jackpot,
      functionName: "getPlayInfos",
      args: [account, [roundIds[currentPlayFetch.roundIdIndex]], currentPlayFetch.cursor, limit],
    });

    if (result.length > 0) {
      playInfos[currentPlayFetch.roundIdIndex].push(...result);
      currentPlayFetch.cursor += limit;
      continue;
    }

    currentPlayFetch.roundIdIndex++;
    currentPlayFetch.cursor = 0;

    if (currentPlayFetch.roundIdIndex >= roundIds.length) {
      currentPlayFetch = null;
    } else {
      playInfos.push([]);
    }
  }

  return playInfos;
}
