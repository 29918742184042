import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useEffect, useMemo, useState } from "react";
import { useAccount } from "wagmi";
import TimeIcon from "../assets/icon/time.png";
import { Loading } from "../common";
import { useNotificationContext } from "../common/NotificationProvider";
import { useCountdown } from "../hooks";
import { useCurrentRound } from "../hooks/contracts/jackpot";
import { SwipeDigit } from "../portion-box-room/SwipeDigit";
import { useJackpotStore } from "../store/jackpot";
import { useNumberStore } from "../store/number-store";
import { bigNumberToTime } from "../utils/formatter";
import { Condition } from "./Condition";
import { NumPad } from "./NumPad";
import { Random } from "./Random";
import { RoundTicketInfo } from "./RoundTicketInfo";
import { SelectedNumber } from "./SelectedNumber";

type StringNumber = number | string | undefined;

export function AddNumber() {
  const numberStore = useNumberStore.getState();
  const { openConnectModal } = useConnectModal();
  const { isConnected } = useAccount();
  const { pushNotification } = useNotificationContext();
  const jackpotStore = useJackpotStore();
  const round = useCurrentRound();
  const [, , countdown] = useCountdown(bigNumberToTime(round?.executeAfterTime), false);

  const digit = 5;
  const digitArray = useMemo(() => [...Array(digit)], [digit]);

  const [numbers, setNumbers] = useState<StringNumber[]>(digitArray);
  const [isSwipe, setIsSwipe] = useState(false);

  const enableSwipeButton = !numbers.some((number) => number === undefined || number === "");

  const handleInputs = (e: any, index: number) => {
    if (!isConnected) {
      openConnectModal?.();
      return;
    }

    const { value } = e.target;
    var numberRegex = /^\d+$/;

    if (!numberRegex.test(value) && value !== "") {
      return;
    }

    let newNumbers = [...numbers];
    newNumbers[index] = value;
    setNumbers(newNumbers);

    if (value !== "") {
      const nextInput = e.target.parentElement?.parentElement?.children[index + 1]
        ?.children[0] as HTMLInputElement;

      if (nextInput) {
        nextInput?.focus();
      } else {
        e.target.blur();
      }
    }
  };

  const activeNumberIndex = useMemo(() => {
    return numbers.findIndex((number) => number === undefined || number === "");
  }, [numbers]);

  const deleteNumberIndex = useMemo(() => {
    for (let index = numbers.length - 1; index >= 0; index--) {
      if (numbers[index] !== undefined && numbers[index] !== "") {
        return index;
      }
    }
    return 0;
  }, [numbers]);

  useEffect(() => {
    const currentNumber = Number(numbers.join(""));
    numberStore.setCurrentNumber(currentNumber);
  }, [numbers, numberStore]);

  function numberInput(number: number) {
    if (!isConnected) {
      openConnectModal?.();
      return;
    }

    if (activeNumberIndex === -1) return;

    let newNumbers = [...numbers];
    newNumbers[activeNumberIndex] = number.toString();
    setNumbers(newNumbers);
  }

  function numberDelete() {
    let newNumbers = [...numbers];
    delete newNumbers[deleteNumberIndex];
    setNumbers(newNumbers);
    setIsSwipe(false);
  }

  function addNumberToStore() {
    const number = Number(numbers.join(""));
    numberStore.addNumber(number, 1, 100);
    setNumbers(digitArray);

    pushNotification({
      type: "add",
      number: numbers.join(""),
      key: Math.random().toString(),
    });
  }

  return (
    <div className="bg-black">
      <div className="relative bg-cover lg:bg-add-number">
        <div className="absolute inset-x-0 top-0 h-[150px] bg-gradient-to-b from-black z-0 lg:block hidden"></div>
        <div className="absolute inset-x-0 bottom-0 h-[200px] bg-gradient-to-t from-black z-0"></div>
        <div className="container relative z-10 mx-auto pb-7">
          <p className="pt-12 text-5xl text-center font-audiowide text-gradient gold">ADD NUMBER</p>
          <p className="pt-2 font-light text-center">
            You can choose from our many options to play, or choose in the number you want
          </p>

          <div className="flex flex-col justify-center mt-12 lg:space-x-6 lg:flex-row">
            <div className="flex flex-col order-last gap-y-4 lg:order-first lg:mt-0">
              <div className="hidden lg:block">
                <Condition />
              </div>
              <div className="relative mt-4 lg:mt-0">
                {jackpotStore.isCalculating ? (
                  <Loading text="Calculating.." />
                ) : (
                  jackpotStore.isClosed && (
                    <div className="absolute inset-0 z-10 flex flex-col items-center justify-center bg-black/90">
                      <img className="w-14" src={require("../assets/icon/time.png")} alt="" />
                      <div className="mt-3 text-lg font-semibold font-audiowide">DRAW IN</div>
                      <div className="mt-3 text-4xl font-semibold font-roboto-condensed text-gradient gold">
                        {countdown}
                      </div>
                      <div className="mt-4 text-base text-center">
                        waiting until the round draw
                        <br /> and winner will be announce
                      </div>
                    </div>
                  )
                )}
                <NumPad
                  digitArray={digitArray}
                  numbers={numbers}
                  handleInputs={handleInputs}
                  numberInput={numberInput}
                  numberDelete={numberDelete}
                  isSwipe={isSwipe}
                  onAddNumberClick={addNumberToStore}
                  onSwiped={() => {
                    setIsSwipe(false);
                    setNumbers(digitArray);
                  }}
                  gold
                />
              </div>
            </div>
            <div className="flex flex-col order-first space-y-3 basis-1/2 lg:order-last">
              <div className="grid grid-cols-2 gap-3">
                <Random />
                <SwipeDigit
                  gold
                  enableSwipeButton={enableSwipeButton}
                  toggleIsSwipe={() => setIsSwipe(!isSwipe)}
                />
              </div>
              <div className="hidden lg:block">
                <RoundTicketInfo />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative lg:bg-gradient-black-to-blue">
        <div className="hidden w-full h-16 bg-gradient-to-b from-black lg:block"></div>
        <div className="relative z-10 lg:container lg:mx-auto">
          {jackpotStore.isCalculating ? (
            <Loading text="Calculating.." />
          ) : (
            jackpotStore.isClosed && <Loading icon={TimeIcon} text="Waiting draw.." />
          )}
          <SelectedNumber />
        </div>

        <div className="container block mt-5 lg:hidden">
          <RoundTicketInfo />
          <div className="mt-4 lg:mt-0">
            <Condition />
          </div>
        </div>

        <img
          alt=""
          className="absolute top-0 left-0 z-0 hidden w-96 opacity-40 lg:block"
          src={require("../assets/cube-trans4.png")}
        />
        <img
          alt=""
          className="absolute bottom-0 right-0 z-0 hidden w-1/3 opacity-50 lg:block"
          src={require("../assets/cube-trans3.png")}
        />
        <div className="w-full h-8 lg:h-20 bg-gradient-to-t from-black"></div>
      </div>
    </div>
  );
}
