import { BigNumber } from "ethers";
import { formatUnits } from "ethers/lib/utils";
import { useMemo } from "react";

type Config = Partial<{ max: number; min: number; unit: number }>;

export const useFormattedBigNumber = (value?: BigNumber, config?: Config) => {
  return useMemo(() => {
    if (value === undefined) return;

    return Number(formatUnits(value, config?.unit ?? "ether")).toLocaleString(undefined, {
      maximumFractionDigits: config?.max ?? 8,
      minimumFractionDigits: config?.min ?? 0,
    });
  }, [value, config]);
};
