import dayjs from "dayjs";
import { BigNumber, utils } from "ethers";

const dateFormat = "MMM D, YYYY, HH:mm";

export const formattedBigNumber = (prefix: string, number?: BigNumber) => {
  if (number !== undefined) {
    return (
      prefix +
      Number(utils.formatUnits(number, 6)).toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })
    );
  }
};

export const bigNumberToTime = (number?: BigNumber) => {
  return number?.mul(1000).toNumber();
};

export const compactAddress = (user: string, length: number = 3) =>
  user.length > 8 ? `${user.slice(0, length)}..${user.slice(-length)}` : user;

export const bigNumberToFormattedTime = (number?: BigNumber) => {
  const time = bigNumberToTime(number);
  return dayjs(time).format(dateFormat);
};

export const bigNumberToFormattedTimeWithFormat = (format: string, number?: BigNumber) => {
  const time = bigNumberToTime(number);
  return dayjs(time).format(format);
};

export const formattedTime = (date: Date) => {
  return dayjs(date).format(dateFormat);
};
