import { motion } from "framer-motion";
import { memo } from "react";
import { AnimateLogo } from "../home/AnimateLogo";

const _AnimateCalculating = () => {
  return (
    <div className="flex items-center gap-x-2">
      <motion.div
        animate={{ translateY: 2 }}
        initial={{ translateY: -2 }}
        transition={{
          repeat: Infinity,
          repeatType: "reverse",
          duration: 1,
          type: "tween",
        }}
      >
        <AnimateLogo className="w-10 rotate-[-26deg]" />
      </motion.div>
      <motion.span
        animate={{ opacity: 0.4 }}
        initial={{ opacity: 1 }}
        transition={{
          repeat: Infinity,
          repeatType: "reverse",
          duration: 1,
          type: "tween",
        }}
      >
        Calculating..
      </motion.span>
    </div>
  );
};

export const AnimateCalculating = memo(_AnimateCalculating);
