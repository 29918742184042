import { useWinningRank } from "../hooks/contracts/jackpot";
import { EmptyWiningPlayer } from "../portion-box/EmptyWiningPlayer";
import { compactAddress } from "../utils/formatter";

export function TableWinningRanks() {
  const ranks = useWinningRank();
  return (
    <div className="pt-5">
      {!!ranks?.length ? (
        <div className="mx-auto max-w-[700px]">
          <p className="py-2 text-base font-light text-center md:text-lg">
            At the beginning of the competition
          </p>

          <div className="grid grid-cols-12 text-[#a7a1a1] font-light lg:text-lg text-xs text-left mt-4 lg:mb-2 mb-0 ">
            <p className="col-span-2 pl-3 pr-2 lg:pl-8 md:pl-5 lg:pr-8 md:pr-5">Place</p>
            <p className="col-span-5 px-1 lg:px-8 md:px-5">Wallet</p>
            <p className="col-span-3 px-1 lg:px-8 md:px-5">Winning prize</p>
          </div>
          <div className="h-[400px] overflow-y-scroll no-scrollbar pb-2">
            {ranks.map((rank, index) => {
              return (
                <div
                  className="grid grid-cols-12 my-2 text-sm font-light text-left bg-blue-gray rounded-xl lg:text-2xl md:text-xl lg:my-3"
                  key={rank.user}
                >
                  <div className="col-span-2 py-3 pl-4 pr-2 font-medium lg:pl-8 md:pl-5 lg:pr-8 md:pr-5 text-gradient gold">
                    #{index + 1}
                  </div>
                  <div className="col-span-5 px-1 py-3 font-medium lg:px-8 md:px-5">
                    {compactAddress(rank.user, 5)}
                  </div>
                  <div className="col-span-3 px-1 py-3 font-bold text-gradient gold lg:px-8 md:px-5">
                    $
                    {Number(rank.total).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <EmptyWiningPlayer />
      )}
    </div>
  );
}
