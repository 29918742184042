import clsx from "clsx";
import { useState } from "react";
import Popup from "reactjs-popup";
import { Button } from "../common";
import { GraphWithPriceCard } from "../common/GraphWithPriceCard";
import { JackpotPriceCard } from "../common/JackpotPriceCard";
import { NotificationProvider } from "../common/NotificationProvider";
import { TabGroup } from "../common/TabGroup";
import { AddNumber } from "../decimal-jackpot/AddNumber";
import { Banner } from "../decimal-jackpot/Banner";
import { Dashboard } from "../decimal-jackpot/Dashboard";
import { RoundNumberFetcher } from "../decimal-jackpot/RoundNumberFetcher";
import { TableMyTicket } from "../decimal-jackpot/TableMyTicket";
import { TableWinningRanks } from "../decimal-jackpot/TableWinningRanks";
import { WinningCriteria } from "../decimal-jackpot/WinningCriteria";
import { useBinanceSocketPriceCallback } from "../hooks/useBinanceSocketPriceCallback";
import { Questions } from "../multiplier-box";
import { ToggleGraphSwitch } from "../portion-box-room/GraphPriceInfo";
import { useAssetPriceStore } from "../store/asset-price";
import { Instruction } from "../decimal-jackpot/Instruction";
import { JackpotTicketProvider } from "../context/JackpotTicketContext";

export function DecimalJackpot() {
  const [activeTab, setActiveTab] = useState(0);
  const [showGraph, setShowGraph] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const setPrice = useAssetPriceStore((s) => s.setPrice);

  useBinanceSocketPriceCallback("btc", (price) => {
    setPrice("btc", price);
  });

  useBinanceSocketPriceCallback("eth", (price) => {
    setPrice("eth", price);
  });

  useBinanceSocketPriceCallback("bnb", (price) => {
    setPrice("bnb", price);
  });

  return (
    <NotificationProvider gold>
      <JackpotTicketProvider>
        <RoundNumberFetcher />
        <Banner />
        <Dashboard />
        <div className="relative pt-4 pb-4 bg-black">
          <img
            alt=""
            className="absolute hidden opacity-30 lg:block"
            src={require("../assets/cube-trans4.png")}
          />
          <img
            alt=""
            className="absolute right-0 hidden bottom-12 w-72 opacity-30 lg:block"
            src={require("../assets/cube-trans.png")}
          />
          <div className="container relative z-10 mx-auto">
            <div className="relative z-10 w-[85%] px-4 py-5 mx-auto overflow-hidden bg-cover rounded-2xl btn-border-gradient-gold">
              <div className="absolute inset-0 z-0 bg-cover lg:bg-graph-toggle bg-[#04132c]"></div>
              <div className="relative flex flex-row items-center space-x-3 lg:justify-center">
                <img
                  alt=""
                  className="object-contain h-4"
                  src={require("../assets/chart-icon@2x.webp")}
                />
                <span className="text-xl font-bold">CHART</span>
                <ToggleGraphSwitch checked={showGraph} onCheckChanged={setShowGraph} jackpot />
              </div>
            </div>
          </div>
        </div>

        <div className={clsx("relative -mt-12 bg-gradient-black", showGraph ? "block" : "hidden")}>
          <div className="container pt-16 pb-6 grid lg:grid-cols-3 px-6 bg-black gap-4 border border-[#353734] rounded-xl lg:h-[465px]">
            <GraphWithPriceCard asset="bnb" />
            <GraphWithPriceCard asset="eth" />
            <GraphWithPriceCard asset="btc" />
          </div>
        </div>

        <div className="pt-6 bg-black">
          <div className="container py-5 mx-auto">
            <JackpotPriceCard />
            <div className="text-center">
              <Popup
                open={modalOpen}
                modal
                nested
                trigger={
                  <button className="mt-4 uppercase border-b border-dashed border-white/70 text-white/70 hover:text-white">
                    How to play ?
                  </button>
                }
                contentStyle={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
                onClose={() => setModalOpen(false)}
                onOpen={() => setModalOpen(true)}
              >
                <div className="max-w-[800px] mx-auto p-2 lg:p-6 overflow-hidden card-border-gradient-gold-bg-black">
                  <img
                    className="hidden md:block"
                    src={require("../assets/jackpot-howto-desktop.jpg")}
                    alt=""
                  />
                  <img
                    className="md:hidden"
                    src={require("../assets/jackpot-howto-mobile.jpg")}
                    alt=""
                  />
                  <div className="mb-4 text-center">
                    <Button
                      className="px-24 py-2 uppercase"
                      preset="goldMax"
                      onClick={() => setModalOpen(false)}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </Popup>
            </div>
          </div>
        </div>
        <AddNumber />
        <div className="relative bg-center bg-cover bg-live-play">
          <div className="absolute inset-x-0 top-0 h-[150px] bg-gradient-to-b from-black"></div>
          <div className="absolute inset-x-0 bottom-0 h-[150px] bg-gradient-to-t from-black"></div>

          <div className="container relative z-10 pb-10 mx-auto">
            <div className="px-2 pt-5 overflow-hidden bg-black-border-gold md:px-9">
              <TabGroup
                color="gold"
                tabs={["My Ticket", "Wining Rank"]}
                className="mx-auto w-max"
                buttonClassName="px-6 py-3 lg:py-3 md:w-[140px] w-36 md:text-base sm:text-xs text-[10px] font-semibold"
                onTabChange={setActiveTab}
              />
              {activeTab === 0 && <TableMyTicket />}
              {activeTab === 1 && <TableWinningRanks />}
            </div>
          </div>
        </div>
        <Instruction />
        <WinningCriteria />
        <Questions />
      </JackpotTicketProvider>
    </NotificationProvider>
  );
}
