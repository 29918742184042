import { BigNumber } from "ethers";
import { useContractEvent } from "wagmi";
import { useJackpotTicket } from "../../../context/JackpotTicketContext";
import Jackpot from "../../../contracts/Jackpot.json";

export const useJackpotWatchEvent = (callback: (roundId: BigNumber) => void) => {
  const jackpotTicket = useJackpotTicket();
  useContractEvent({
    addressOrName: jackpotTicket.contractAddress,
    contractInterface: Jackpot,
    eventName: "StartRound",
    listener(node) {
      const [roundId] = node;
      callback(roundId);
    },
  });
};
