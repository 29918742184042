import clsx from "clsx";
import { ethers, utils } from "ethers";
import { useCallback, useMemo, useState } from "react";
import { useAccount, useBalance } from "wagmi";
import successWhite from "../assets/success-white.svg";
import SuccessIcon from "../assets/success.svg";
import { Button } from "../common/Button";
import { Loading } from "../common/Loading";
import { Modal } from "../common/Modal";
import { useNotificationContext } from "../common/NotificationProvider";
import { NumberLimitModal } from "../common/NumberLimitModal";
import { address, config } from "../config";
import { WriteError, useCurrentRound, useJackpotWrite } from "../hooks/contracts/jackpot";
import { TokenPathKey } from "../hooks/contracts/useTicketPrice";
import { useTokenAllowance } from "../hooks/contracts/useTokenAllowance";
import { useTokenWrite } from "../hooks/contracts/useTokenWrite";
import { useFormattedBigNumber } from "../hooks/useFormattedBigNumber";
import { useTokenTotalAmount } from "../multiplier-box/play/modal/hooks/useTokenTotalAmount";
import { useJackpotStore } from "../store/jackpot";
import { useNumberStore } from "../store/number-store";
import { useJackpotTicket } from "../context/JackpotTicketContext";

type BuyNumberProps = {
  isOpen: boolean;
  closeModal: any;
  gold?: boolean;
};

type Token = {
  icon: any;
  name: TokenPathKey;
  address?: string;
  gasToken?: boolean;
  decimals: number;
};

const tokens: Token[] = [
  {
    icon: require("../assets/icon/usdt.png"),
    name: "usdt",
    address: address.USDT_ADDRESS,
    decimals: 18,
  },
  {
    icon: require("../assets/icon/busd.png"),
    name: "busd",
    address: address.BUSD_ADDRESS,
    decimals: 18,
  },
  {
    icon: require("../assets/icon/usdc.png"),
    name: "usdc",
    address: address.USDT_ADDRESS,
    decimals: 18,
  },
  {
    icon: require("../assets/icon/bnb.png"),
    name: "bnb",
    gasToken: true,
    decimals: 18,
  },
];

const bboxTokens: Token[] = [
  {
    icon: require("../assets/logo1.png"),
    name: "bbox",
    address: address.BBOX_ADDRESS,
    decimals: 18,
  },
];

export function BuyNumberModal({ isOpen, closeModal, gold }: BuyNumberProps) {
  const jackpotTicket = useJackpotTicket();
  const account = useAccount();
  const { numbers, runFetchCallback, clearNumbers } = useNumberStore();
  const currentRound = useCurrentRound();
  const { pushNotification } = useNotificationContext();
  const [limitNumbers, setLimitNumbers] = useState<number[]>([]);

  const [selectedToken, setSelectedToken] = useState(
    jackpotTicket.currentTicket === "usdt" ? tokens[0] : bboxTokens[0]
  );

  const [isLoading, setIsLoading] = useState(false);

  const availableTokens = useMemo(() => {
    return jackpotTicket.currentTicket === "usdt" ? tokens : bboxTokens;
  }, [jackpotTicket.currentTicket]);

  const { data: balanceResult } = useBalance({
    addressOrName: account.address,
    token: selectedToken.address,
    watch: true,
  });

  const formattedSelectedTokenBalance = useFormattedBigNumber(balanceResult?.value, {
    max: 4,
    unit: selectedToken.decimals,
  });

  const totalSize = useMemo(() => {
    return numbers.flatMap((number) => number.size).reduce((prev, cur) => prev + cur, 0);
  }, [numbers]);

  const price = useMemo(
    () => totalSize * config.jackpotPrice(jackpotTicket.currentTicket),
    [totalSize, jackpotTicket]
  );

  const totalPrice = useTokenTotalAmount(price, selectedToken.gasToken, "bnb");
  const totalPriceEther = useMemo(() => utils.parseEther(totalPrice.toString()), [totalPrice]);

  const isEnoughBalance = useMemo(() => {
    return (
      balanceResult?.value.gte(utils.parseUnits(totalPrice.toString(), selectedToken.decimals)) ??
      true
    );
  }, [balanceResult?.value, totalPrice, selectedToken]);

  const formattedPrice = useMemo(() => {
    if (selectedToken.gasToken) {
      return totalPrice.toLocaleString("en-US", { minimumFractionDigits: 6 });
    } else {
      return totalPrice.toLocaleString("en-US");
    }
  }, [totalPrice, selectedToken]);

  const contractAddress = jackpotTicket.contractAddress;

  const { allowance, refetch: refetchAllowance } = useTokenAllowance(
    selectedToken.address ?? "",
    account.address,
    contractAddress
  );

  const { writeAsync: executeApprove } = useTokenWrite(
    selectedToken.address ?? "",
    !selectedToken.gasToken,
    "approve",
    [contractAddress, ethers.constants.MaxUint256]
  );

  const { writeAsync: executePlay } = useJackpotWrite(
    "play",
    [numbers.map((n) => n.number), currentRound?.roundId, selectedToken.address],
    handleWriteError
  );

  const { writeAsync: executePlayNativeToken } = useJackpotWrite(
    "playNativeToken",
    [totalPriceEther, numbers.map((n) => n.number), currentRound?.roundId],
    handleWriteError
  );

  const approve = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await executeApprove?.();
      if (result) {
        pushNotification({
          key: result.hash,
          type: "sendTx",
          txhash: result.hash,
        });
      }
      await result?.wait(1);
      refetchAllowance();
    } finally {
      setIsLoading(false);
    }
  }, [executeApprove, refetchAllowance]);

  const buy = useCallback(async () => {
    setIsLoading(true);
    let buyResult: any;
    try {
      switch (selectedToken.name) {
        case "bnb":
          buyResult = await executePlayNativeToken();
          break;
        default:
          buyResult = await executePlay();
          break;
      }

      pushNotification({
        key: buyResult.hash,
        type: "sendTx",
        txhash: buyResult.hash,
      });

      const roundBuyers = useJackpotStore.getState().roundBuyers;
      const newRoundBuyer = {
        roundId: currentRound?.roundId.toNumber() ?? 0,
        user: account.address ?? "",
        number: numbers.map((num) => num.number),
        transactionAt: new Date().toISOString(),
      };

      useJackpotStore.getState().setRoundBuyers([...roundBuyers, newRoundBuyer]);

      await buyResult?.wait(1);

      runFetchCallback();

      pushNotification({
        key: buyResult.hash,
        type: "success",
        text: "Your selected numbers have been bought successfully!",
      });

      setIsLoading(false);
      clearNumbers();
      closeModal();
    } catch (error) {
      pushNotification({
        key: Math.random().toString(),
        type: "failed",
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    executePlay,
    selectedToken,
    closeModal,
    clearNumbers,
    runFetchCallback,
    executePlayNativeToken,
    pushNotification,
  ]);

  async function handleWriteError(writeError: WriteError) {
    if (writeError === "NumberLimitExceeded") {
      const purchasedNumbers = useJackpotStore.getState().getRoundNumbers();
      const limitNumbers: number[] = [];
      const lastNumberIndex = numbers.length - 1;
      const { deleteNumber } = useNumberStore.getState();

      for (let index = lastNumberIndex; index >= 0; index--) {
        const number = numbers[index];
        const duplicateNumber = purchasedNumbers.some((purchasedNumber) => {
          return purchasedNumber === number.number;
        });

        if (duplicateNumber) {
          limitNumbers.push(number.number);
          deleteNumber(index);
        }
      }

      if (limitNumbers.length > 0) {
        setLimitNumbers(limitNumbers);
      }
    }
  }

  function close() {
    if (!isLoading) closeModal?.();
  }

  return (
    <Modal active={isOpen} onDismiss={close}>
      <div className="modal-card">
        <header
          className={clsx(
            "justify-between border-b-0 modal-card-head ",
            gold ? "bg-gradient-gold-max" : "bg-gradient-blue"
          )}
        >
          <p className="text-xl text-white uppercase md:text-3xl modal-card-title font-audiowide">
            BUY number
          </p>
          <button onClick={close}>
            <img
              src={require("../assets/icon/close.png")}
              alt=""
              className={clsx("w-5 h-5 lg:w-7 lg:h-7", isLoading && "opacity-30")}
            />
          </button>
        </header>
        <section className="p-0 divide-y modal-card-body bg-dark-blue-gray divide-dashed">
          <div className="relative">
            {isLoading && <Loading />}

            <div className="grid grid-cols-3 gap-2 p-3 lg:grid-cols-4 md:gap-4 md:px-9 md:pt-6 md:pb-2 max-h-[300px] overflow-y-scroll scrollbar-gray">
              {numbers.map((number, index) => {
                return (
                  <div key={index} className="py-3 text-center card-border-brown-bg-blue">
                    <p>
                      <span className="text-base font-bold tracking-widest md:text-xl text-gradient gold">
                        {number.number.toString().padStart(5, "0")}
                      </span>
                      <span className="ml-3 text-sm font-medium md:text-lg">X{number.size}</span>
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="p-3 md:p-9">
              <p className="mb-5 text-xl font-medium text-gradient gold">Payment</p>

              <div
                className={clsx(
                  "grid gap-3 lg:gap-6 md:gap-3",
                  jackpotTicket.currentTicket === "usdt" ? "grid-cols-4" : "grid-cols-2"
                )}
              >
                {availableTokens.map((token) => {
                  return (
                    <button
                      key={token.name}
                      className="relative flex flex-col items-center py-4 overflow-hidden card-border-brown-bg-blue"
                      onClick={() => setSelectedToken(token)}
                    >
                      {selectedToken.name === token.name && (
                        <div className="absolute inset-0 flex items-center justify-center rounded-lg">
                          <div
                            className={clsx(
                              "absolute inset-0 z-0 opacity-70",
                              gold ? "bg-gradient-gold-max" : "bg-gold"
                            )}
                          ></div>
                          <img
                            src={gold ? successWhite : SuccessIcon}
                            className="z-10 w-8"
                            alt="Selected icon"
                          />
                        </div>
                      )}
                      <img className="mb-2 w-7 lg:w-9" src={token.icon} alt="" />
                      <p className="text-base font-semibold">{token.name.toUpperCase()}</p>
                    </button>
                  );
                })}
              </div>

              <div className="flex justify-between mt-8">
                <p className="text-sm font-bold lg:text-base">Total ({numbers.length} numbers)</p>
                <p className="text-sm font-normal lg:text-base text-brownish-grey">
                  Balance {formattedSelectedTokenBalance ?? 0} {selectedToken.name.toUpperCase()}
                </p>
              </div>
              <div className="flex items-center justify-between px-4 py-2 mt-1 bg-black/40 rounded-2xl lg:mt-4">
                <p className="text-lg font-bold text-brownish-grey">You Pay</p>
                <div className="flex items-center">
                  <p className="mr-5 text-2xl font-bold lg:text-3xl">{formattedPrice}</p>
                  <div className="flex flex-col items-center w-[50px]">
                    <img className="w-6 mb-1" src={selectedToken.icon} alt="" />
                    <p className="text-xs font-semibold lg:text-lg">
                      {selectedToken.name.toUpperCase()}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col justify-between mt-8 md:flex-row">
                <div className="flex items-center">
                  <img
                    className="w-8 mr-3"
                    src={require("../assets/icon/question@2x.png")}
                    alt=""
                  />
                  <div>
                    <p className="font-bold">Prize Condition</p>
                    <p className="text-xs font-light">
                      1 Number = {config.jackpotPrice(jackpotTicket.currentTicket)}{" "}
                      {jackpotTicket.currentTicket.toUpperCase()}
                    </p>
                  </div>
                </div>

                {!selectedToken.gasToken && (!allowance || totalPrice > allowance) ? (
                  <Button
                    className="w-full mt-5 uppercase lg:mt-0 md:w-80"
                    preset={gold ? "goldMax" : "gold"}
                    buttonSize="md"
                    onClick={approve}
                  >
                    APPROVE
                  </Button>
                ) : (
                  <Button
                    className="w-full mt-5 uppercase lg:mt-0 md:w-80"
                    preset={gold ? "goldMax" : "gold"}
                    buttonSize="md"
                    onClick={buy}
                    disabled={!isEnoughBalance}
                  >
                    {isEnoughBalance ? "CONFIRM" : "INSUFFICIENT BALANCE"}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <NumberLimitModal
        gold
        numbers={limitNumbers}
        isOpen={limitNumbers.length > 0}
        closeModal={() => setLimitNumbers([])}
      />
    </Modal>
  );
}
