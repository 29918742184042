import { useMemo } from "react";
import { useJackpotTicket } from "../../../context/JackpotTicketContext";

export function useTicketIcon() {
  const jackpotTicket = useJackpotTicket();

  const ticketIcon = useMemo(() => {
    return jackpotTicket.currentTicket === "usdt"
      ? require("../../../assets/icon/usdt.png")
      : require("../../../assets/bb-icon1.png");
  }, [jackpotTicket.currentTicket]);

  return ticketIcon;
}
