import { motion } from "framer-motion";
import { AnimatedText } from "../common/AnimatedText";
import { useJackpotTicket } from "../context/JackpotTicketContext";
import { config } from "../config";

export function Instruction() {
  const jackpotTicket = useJackpotTicket();

  return (
    <div className="relative bg-gradient-black-to-blue">
      <div className="absolute inset-x-0 top-0 lg:h-[150px] h-[100px] bg-gradient-to-b from-black z-0"></div>
      <img
        alt=""
        className="absolute left-0 z-0 top-24 w-96"
        src={require("../assets/cube-trans4.png")}
      />
      <div className="container py-12 mx-auto">
        <motion.div
          className="mt-12 text-center"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "0px 0px -20% 0px" }}
          transition={{ delayChildren: 0.2, staggerChildren: 0.04 }}
        >
          <AnimatedText size="md" text="I" goldMax />
          <AnimatedText size="md" text="N" goldMax />
          <AnimatedText size="md" text="S" goldMax />
          <AnimatedText size="md" text="T" goldMax />
          <AnimatedText size="md" text="R" goldMax />
          <AnimatedText size="md" text="U" goldMax />
          <AnimatedText size="md" text="C" goldMax />
          <AnimatedText size="md" text="T" goldMax />
          <AnimatedText size="md" text="I" goldMax />
          <AnimatedText size="md" text="O" goldMax />
          <AnimatedText size="md" text="N" goldMax />
        </motion.div>
        <div className="mt-6 text-center max-w-[700px] mx-auto px-4 flex flex-col gap-y-2 text-lg">
          <p>
            Join the excitement by purchasing a ticket for just{" "}
            {config.jackpotPrice(jackpotTicket.currentTicket).toLocaleString("en-US")}{" "}
            {jackpotTicket.currentTicket.toUpperCase()}.
          </p>

          <p>
            Select your set of 5 digits for each ticket, and you have the option to choose manually
            or go with automatic random selection.
          </p>

          <p>Prize are drawn at 13:00 UTC+0 every day.</p>

          <p>Ticket sales conclude at 12:55 UTC+0 every day, five minutes before the draw. </p>

          <p>
            Our drawing algorithm is derived from the decimal fractions of BNB, ETH, and BTC on
            Binance exchange. To validate, simply review the minute time frame close price on
            Binance 13:00 UTC+0.
          </p>

          <p>Each ticket holds a unique combination. </p>

          <p>
            It's important to note that winners will receive only the highest prize among their
            tickets.
          </p>
        </div>
        <div className="bg-white/20 max-w-[300px] h-[1px] mx-auto mt-24"></div>
      </div>
    </div>
  );
}
