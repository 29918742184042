import { Link } from "react-router-dom";

export function Footer() {
  return (
    <div className="px-4 pt-6 bg-black lg:px-20 pb-14">
      <div className="relative p-10 mt-16 bg-gradient-blue rounded-3xl">
        <div className="absolute inset-x-0 top-[-25px] left-[50px] h-[55px] bg-blur-blue z-0"></div>

        <div className="relative z-10 grid grid-cols-1 gap-y-10 lg:grid-cols-2">
          <img
            alt=""
            className="z-10 w-full max-w-[400px]"
            src={require("../assets/logo@2x.png")}
          />
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-8">
            <div className="text-center lg:text-left">
              <p className="mb-3 text-2xl font-bold text-gradient gold">ABOUT</p>
              <Link to="/">
                <p className="mb-3 uppercase">Github</p>
              </Link>
              <a href="https://info.billionbox.io/">
                <p className="mb-3 uppercase">Documentation</p>
              </a>
              <a href="https://linktr.ee/billionbox">
                <p className="uppercase">CONTACT</p>
              </a>
            </div>
            <div className="text-center lg:text-left">
              <p className="mb-3 text-2xl font-bold text-gradient gold">GAMES</p>
              <a href="https://billionbox.io/decimal-box">
                <p className="mb-3 uppercase">The Decimal Box</p>
              </a>
              <a href="https://billionbox.io/prediction-pro/high-low">
                <p className="mb-4 uppercase">The Prediction Box</p>
              </a>
              <a href="https://billionbox.io/bb-max">
                <p className="mb-4 uppercase">BB Max (Lotto)</p>
              </a>
              <a href="/jackpot">
                <p className="mb-4 uppercase">Jackpot</p>
              </a>
              <div className="flex justify-center lg:justify-start">
                <a href="https://twitter.com/Billionbox_io">
                  <img className="w-8 mr-4" src={require("../assets/icon/twitter.png")} alt="" />
                </a>
                <a href="https://discord.com/invite/8fatVAKyxG">
                  <img className="w-8 mr-4" src={require("../assets/icon/discord.png")} alt="" />
                </a>
                <a href="https://t.me/billionboxgroup">
                  <img className="w-8 mr-4" src={require("../assets/icon/telegram.png")} alt="" />
                </a>
                <a href="https://medium.com/@billionboxio">
                  <img className="w-8" src={require("../assets/icon/medium.png")} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <p className="pt-8 text-sm text-center lg:pt-5 lg:text-left">
          @2023 BillionBox. All rights reserved
        </p>
        <img
          className="absolute top-0 left-0 z-0 rounded-tl-3xl"
          src={require("../assets/cube-trans2.png")}
          alt=""
        />
        <img
          className="absolute right-0 block top-44 md:w-60 w-44 lg:hidden"
          src={require("../assets/cube-trans6.png")}
          alt=""
        />
        <img
          className="absolute bottom-0 right-0 hidden w-64 lg:block"
          src={require("../assets/cube-trans3.png")}
          alt=""
        />
      </div>
    </div>
  );
}
