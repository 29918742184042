import { useMemo } from "react";
import { findWinningPrize } from "../../../decimal-jackpot/utils/gameWinning";
import { useJackpotRead } from "./useJackpotRead";
import { useJackpotWatchEvent } from "./useJackpotWatchEvent";
import { usePlayInfos } from "./usePlayInfos";

export function useReward(account?: string) {
  const { roundIds, playInfos, pending, refetch } = usePlayInfos(account);
  const { data: rounds = [], contractRead } = useJackpotRead({
    functionName: "getRounds",
    args: [roundIds],
  });

  const userReward = useMemo(() => {
    let reward = 0;
    const rewardRoundIds: number[] = [];
    const playInfoIndexes: number[] = [];
    const gameAddresses: string[] = [];

    if (!playInfos?.length || !rounds?.length) {
      return { reward, rewardRoundIds, playInfoIndexes, gameAddresses };
    }

    if (rounds.length && playInfos.length && rounds.length === playInfos.length) {
      for (let index = 0; index < playInfos.length; index++) {
        const round = rounds[index];

        if (round.executedTime.eq(0)) continue;

        const roundPlayInfos = playInfos[index];

        for (const [playInfoIndex, playInfo] of roundPlayInfos.entries()) {
          const prize = findWinningPrize(playInfo, round.answer, false);
          if (prize.win && prize.game?.address) {
            rewardRoundIds.push(round.roundId.toNumber());
            playInfoIndexes.push(playInfoIndex);
            gameAddresses.push(prize.game.address);
            reward += prize.prize;
          }
        }
      }
    }
    return { reward, rewardRoundIds, playInfoIndexes, gameAddresses };
  }, [rounds, playInfos]);

  useJackpotWatchEvent(() => {
    contractRead.refetch();
  });

  return {
    ...userReward,
    pending,
    refetch: () => {
      refetch();
      contractRead.refetch();
    },
  };
}
