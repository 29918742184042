export function getAllPermutationNumbers(number: string) {
  const numbers: string[] = [];

  function permute(str: string, l: number, r: number) {
    if (l === r) numbers.push(str);
    else {
      for (let i = l; i <= r; i++) {
        str = swap(str, l, i);
        permute(str, l + 1, r);
        str = swap(str, l, i);
      }
    }
  }

  function swap(a: string, i: number, j: number) {
    const charArray = a.split('');
    const temp = charArray[i];
    charArray[i] = charArray[j];
    charArray[j] = temp;
    return charArray.join('');
  }

  permute(number, 0, number.length - 1);
  return numbers;
}

export function getAllPermutationNumbersMap(number: string) {
  const numbers: Record<string, boolean> = {};

  function permute(str: string, l: number, r: number) {
    if (l === r) numbers[str] = true;
    else {
      for (let i = l; i <= r; i++) {
        str = swap(str, l, i);
        permute(str, l + 1, r);
        str = swap(str, l, i);
      }
    }
  }

  function swap(a: string, i: number, j: number) {
    const charArray = a.split('');
    const temp = charArray[i];
    charArray[i] = charArray[j];
    charArray[j] = temp;
    return charArray.join('');
  }

  permute(number, 0, number.length - 1);
  return numbers;
}
