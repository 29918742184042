import { BigNumber } from "ethers";
import { useContractEvent } from "wagmi";
import { address } from "../../../config";
import Jackpot from "../../../contracts/Jackpot.json";
import { useJackpotTicket } from "../../../context/JackpotTicketContext";

type Callback = (
  player: string,
  roundId: string,
  numbers: BigNumber[],
  totalAmount: BigNumber,
  timestamp: BigNumber
) => void;

export const useJackpotPlayWatchEvent = (callback: Callback) => {
  const jackpotTicket = useJackpotTicket();
  useContractEvent({
    addressOrName: jackpotTicket.contractAddress,
    contractInterface: Jackpot,
    eventName: "Play",
    listener(node) {
      const [player, roundId, numbers, totalAmount, timestamp] = node;
      callback(player, roundId, numbers, totalAmount, timestamp);
    },
  });
};
