import { motion } from "framer-motion";
import { AnimateLogo } from "../home/AnimateLogo";

type Props = {
  text?: string;
  icon?: any;
};

export const Loading = ({ text = "Loading...", icon }: Props) => {
  return (
    <div className="absolute inset-0 z-10 flex flex-col items-center justify-center bg-black/80">
      <motion.div
        initial={{ y: -10 }}
        animate={{ y: 0 }}
        transition={{ repeat: Infinity, repeatType: "reverse", duration: 1.25 }}
      >
        {icon ? (
          <img className="w-[40px]" src={icon} alt="" />
        ) : (
          <AnimateLogo className="w-[70px] -rotate-[24deg]" />
        )}
      </motion.div>
      <motion.div
        className="mt-2 text-xs font-audiowide"
        initial={{ opacity: 1 }}
        animate={{ opacity: 0.5 }}
        transition={{ repeat: Infinity, repeatType: "reverse", duration: 0.7 }}
      >
        {text}
      </motion.div>
    </div>
  );
};
