import { useEffect } from "react";
import { JackpotAsset, assetPairs } from "../config";

export function useBinanceSocketPriceCallback(
  asset: JackpotAsset,
  callback: (price: number) => void
) {
  useEffect(() => {
    const ws = new WebSocket("wss://stream.binance.com:9443/ws");
    const msg = {
      method: "SUBSCRIBE",
      params: [`${assetPairs[asset].toLowerCase()}@trade`],
      id: 1,
    };

    let latestPrice: number;

    ws.onopen = () => {
      ws.send(JSON.stringify(msg));
    };

    ws.onmessage = ({ data }) => {
      const parsedData = JSON.parse(data);
      if (parsedData?.e === "trade") {
        latestPrice = Number(parsedData.p);
      }
    };

    const flush = () => {
      if (latestPrice) {
        callback(latestPrice);
      }
    };

    const interval = setInterval(flush, 200);

    return () => {
      clearInterval(interval);
      if (ws.readyState !== WebSocket.CLOSED) {
        ws.close();
      }
    };
  }, [asset, callback]);
}
