import { useJackpotTicket } from "../../../context/JackpotTicketContext";
import { useRequest } from "../../useRequest";

type Rank = {
  total: string;
  user: string;
};

type RankResponse = {
  ranking: Rank[];
};

export function useWinningRank() {
  const jackpotTicket = useJackpotTicket();
  const { data } = useRequest<RankResponse>({
    path: `/jackpot/ranking?contract=${jackpotTicket.contractAddress}`,
    config: { refreshInterval: 60000 },
  });

  return data?.ranking;
}
