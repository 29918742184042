import { BigNumberish } from "ethers";
import { useJackpotRead } from "./useJackpotRead";

export const useRoundNumberLength = (roundId?: BigNumberish) => {
  const { data } = useJackpotRead({
    functionName: "getRoundNumberLength",
    args: roundId,
    enabled: !!roundId,
    watch: true,
  });

  return data?.toNumber();
};
