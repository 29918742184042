import { BigNumberish } from "ethers";
import { useJackpotRead } from "./useJackpotRead";

export const useRounds = (roundId?: BigNumberish[]) => {
  const { data: rounds, contractRead } = useJackpotRead({
    functionName: "getRounds",
    args: [roundId ?? []],
    enabled: !!roundId?.length,
  });

  return { rounds, contractRead };
};
