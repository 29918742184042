export function getDecimal(position: number, number?: number) {
  if (!number) return 0;

  const str = number.toString().split(".");
  if (str.length === 1) {
    return 0;
  }
  const decimal = str[1].slice(position, position + 1);
  return !decimal ? 0 : Number(decimal);
}

export function getDecimals(length: number, number?: number) {
  if (!number) return 0;

  const str = number.toString().split(".");
  if (str.length === 1) {
    return 0;
  }
  const decimal = str[1].slice(0, length);
  return !decimal ? 0 : decimal.length === 1 ? Number(decimal) * 10 ** (length - 1) : Number(decimal);
}
