import { useEffect, useState } from "react";
import {
  useCurrentRound,
  useJackpotPlayWatchEvent,
  useRoundBuyers,
} from "../hooks/contracts/jackpot";
import { useJackpotStore } from "../store/jackpot";

export const RoundNumberFetcher = () => {
  const [latestRoundId, setLatestRoundId] = useState(0);
  const currentRound = useCurrentRound();
  const { buyers, mutate } = useRoundBuyers(currentRound?.roundId, 10_000);

  useJackpotPlayWatchEvent(() => {
    // Timeout wait for backend to fetch new buyers
    setTimeout(() => mutate(), 1000);
  });

  useEffect(() => {
    if (buyers === undefined || currentRound === undefined) return;

    if (latestRoundId !== currentRound?.roundId.toNumber()) {
      useJackpotStore.getState().setRoundBuyers([]);
      setLatestRoundId(currentRound.roundId.toNumber());
      return;
    }

    useJackpotStore.getState().setRoundBuyers(buyers);
  }, [buyers, currentRound, latestRoundId]);

  return null;
};
