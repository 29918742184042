import clsx from "clsx";
import { useMemo } from "react";
import { CountUpNumber } from "./CountUpNumber";
import { NumberAssetCircleCard } from "./NumberAssetCircleCard";

type Props = {
  number: number;
  icon?: boolean;
  countAnimate?: boolean;
  className?: string;
  containerClassName?: string;
  highlightCount?: number;
};

export const JackpotPriceNumbers = ({
  number,
  icon,
  className,
  countAnimate,
  containerClassName,
  highlightCount = 5,
}: Props) => {
  const paddedNumber = useMemo(() => number.toString().padStart(Number(5), "0"), [number]);
  const firstDigit = useMemo(() => Number(paddedNumber[0]), [paddedNumber]);
  const secondDigit = useMemo(() => Number(paddedNumber[1]), [paddedNumber]);
  const thirdDigit = useMemo(() => Number(paddedNumber[2]), [paddedNumber]);
  const fourthDigit = useMemo(() => Number(paddedNumber[3]), [paddedNumber]);
  const fifthDigit = useMemo(() => Number(paddedNumber[4]), [paddedNumber]);

  return (
    <div className={clsx("flex flex-row", containerClassName)}>
      <NumberAssetCircleCard
        icon={icon}
        asset="bnb"
        className={clsx("aspect-square", className, highlightCount < 1 && "opacity-30")}
      >
        {countAnimate ? (
          <CountUpNumber startCounting duration={0.8} end={firstDigit} decimals={0} prefix="" />
        ) : (
          <span>{firstDigit}</span>
        )}
      </NumberAssetCircleCard>
      <NumberAssetCircleCard
        icon={icon}
        asset="eth"
        className={clsx("aspect-square", className, highlightCount < 2 && "opacity-30")}
      >
        {countAnimate ? (
          <CountUpNumber startCounting duration={0.8} end={secondDigit} decimals={0} prefix="" />
        ) : (
          <span>{secondDigit}</span>
        )}
      </NumberAssetCircleCard>
      <NumberAssetCircleCard
        icon={icon}
        asset="eth"
        className={clsx("aspect-square", className, highlightCount < 3 && "opacity-30")}
      >
        {countAnimate ? (
          <CountUpNumber startCounting duration={0.8} end={thirdDigit} decimals={0} prefix="" />
        ) : (
          <span>{thirdDigit}</span>
        )}
      </NumberAssetCircleCard>
      <NumberAssetCircleCard
        icon={icon}
        asset="btc"
        className={clsx("aspect-square", className, highlightCount < 4 && "opacity-30")}
      >
        {countAnimate ? (
          <CountUpNumber startCounting duration={0.8} end={fourthDigit} decimals={0} prefix="" />
        ) : (
          <span>{fourthDigit}</span>
        )}
      </NumberAssetCircleCard>
      <NumberAssetCircleCard
        icon={icon}
        asset="btc"
        className={clsx("aspect-square", className, highlightCount < 5 && "opacity-30")}
      >
        {countAnimate ? (
          <CountUpNumber startCounting duration={0.8} end={fifthDigit} decimals={0} prefix="" />
        ) : (
          <span>{fifthDigit}</span>
        )}
      </NumberAssetCircleCard>
    </div>
  );
};
