import { motion, Variants } from "framer-motion";
import { AnimatedText } from "../common/AnimatedText";
import { Button, LinkButton } from "../common/Button";

export function OurGame() {
  const bgVariants: Variants = {
    hidden: { backgroundPositionY: "0px", opacity: 0, scale: 1.4 },
    visible: {
      backgroundPositionY: "1500px",
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.7,
        backgroundPositionY: {
          duration: 60,
          repeat: Infinity,
          repeatType: "mirror",
        },
      },
    },
  };

  const containerVariants: Variants = {
    hidden: { opacity: 0, scale: 1.1 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { delay: 0.5, type: "spring", stiffness: 100, damping: 30 },
    },
  };
  return (
    <div className="overflow-hidden bg-black">
      <motion.div
        className="py-20 text-center bg-our-game"
        initial="hidden"
        whileInView="visible"
        variants={bgVariants}
        viewport={{ once: true, margin: "0px 0px -20% 0px" }}
        transition={{ staggerChildren: 0.03 }}
      >
        <motion.div className="container mx-auto" variants={containerVariants}>
          <motion.div
            className="text-center"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "0px 0px -20% 0px" }}
            transition={{ delayChildren: 0.5, staggerChildren: 0.03 }}
          >
            <AnimatedText text="O" />
            <AnimatedText text="U" />
            <AnimatedText text="R" />
            <AnimatedText className="ml-2" text="G" gold />
            <AnimatedText text="A" gold />
            <AnimatedText text="M" gold />
            <AnimatedText text="E" gold />
            <AnimatedText text="S" gold />
          </motion.div>
          <p className="text-lg lg:text-xl">Let’s Rich , Simple & Fun to Make Money</p>

          <div className="grid grid-cols-1 gap-20 mt-24 lg:gap-5 lg:grid-cols-3">
            <div className="flex flex-col items-center px-8 card-border-gradient-gray-to-white">
              <img
                alt=""
                className="relative -mt-10 lg:w-32 w-28"
                src={require("../assets/box.png")}
              />
              <p className="mt-5 text-3xl font-bold">The Decimal Box</p>
              <div className="flex-1 mt-2 text-lg font-light">
                Win Big with Decimal Pool :Guess the BTC/USD Price Decimals
              </div>

              <LinkButton
                preset="blue"
                to="https://billionbox.io/decimal-box"
                className="rounded-2xl text-lg px-24 p-2 my-5 bg-gradient-to-tr from-[#1921ae] to-[#1a60f9] font-bold"
              >
                PLAY
              </LinkButton>

              <a
                href="https://info.billionbox.io/products/the-decimal-box"
                target="_blank"
                rel="noopener noreferrer"
                className="pb-6 text-lg"
              >
                Learn more {">"}
              </a>
            </div>

            <div className="flex flex-col items-center px-8 card-border-gradient-gray-to-white">
              <img
                alt=""
                className="relative -mt-10 lg:w-32 w-28"
                src={require("../assets/user-blink.png")}
              />
              <p className="mt-5 text-3xl font-bold">The Prediction Box</p>
              <div className="flex-1 mt-2 text-lg font-light">
                The Ultimate Prediction Gaming Platform on Web 3.0
              </div>

              <LinkButton
                to="https://billionbox.io/prediction-pro/high-low"
                preset="blue"
                className="rounded-2xl text-lg px-24 p-2 my-5 bg-gradient-to-tr from-[#1921ae] to-[#1a60f9] font-bold"
              >
                PLAY
              </LinkButton>

              <a
                href="https://info.billionbox.io/products/the-prediction-box"
                target="_blank"
                rel="noopener noreferrer"
                className="pb-6 text-lg"
              >
                Learn more {">"}
              </a>
            </div>

            <div className="flex flex-col items-center px-8 card-border-gradient-gray-to-white">
              <img
                alt=""
                className="relative -mt-10 lg:w-32 w-28"
                src={require("../assets/bb-max.png")}
              />
              <p className="mt-5 text-3xl font-bold">The BB MAX</p>
              <div className="flex-1 mt-2 text-lg font-light">
                A High-Stakes Game for Big Rewards on Billion Box
              </div>
              <LinkButton
                preset="blue"
                to="https://billionbox.io/bb-max"
                className="rounded-2xl text-lg px-24 p-2 my-5 bg-gradient-to-tr from-[#1921ae] to-[#1a60f9] font-bold"
              >
                PLAY
              </LinkButton>
              <a
                href="https://info.billionbox.io/products/the-bb-max"
                target="_blank"
                rel="noopener noreferrer"
                className="pb-6 text-lg"
              >
                Learn more {">"}
              </a>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
}
