import { useState } from "react";
import { JackpotRoundTicketList } from "../common/JackpotRoundTicketList";
import { skeletonWhileLoading } from "../common/Skeleton";
import { TabGroup } from "../common/TabGroup";
import { useCurrentRound, useRoundNumberLength } from "../hooks/contracts/jackpot";
import { useJackpotStore } from "../store/jackpot";
import { FinishedRound } from "./FinishedRound";

export const RoundTicketInfo = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const currentRound = useCurrentRound();
  const numberLength = useRoundNumberLength(currentRound?.roundId);
  const roundNumbers = useJackpotStore((state) => state.roundBuyers);

  return (
    <div className="pt-4 overflow-y-auto bg-black-border-gold no-scrollbar">
      <TabGroup
        color="gold"
        initialActiveTabIndex={activeTabIndex}
        tabs={["This Round", "Finished Round"]}
        className="items-center justify-center mx-auto rounded-full bg-blue-gray w-max"
        buttonClassName="text-sm lg:font-semibold px-4"
        onTabChange={setActiveTabIndex}
      />

      {activeTabIndex === 0 && (
        <>
          <p className="my-3 text-center">
            <span className="text-gold">
              {skeletonWhileLoading({
                value: numberLength?.toLocaleString("en-US"),
                skeletonClassName: "w-12",
              })}{" "}
              Numbers
            </span>{" "}
            in this Round!
          </p>
          <div className="h-[1px] bg-gold"></div>
          <JackpotRoundTicketList buyers={roundNumbers ?? []} />
        </>
      )}

      {activeTabIndex === 1 && (
        <div className="px-4 overflow-y-auto no-scroll">
          <FinishedRound />
        </div>
      )}
    </div>
  );
};
