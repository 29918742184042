import { useVirtualizer } from "@tanstack/react-virtual";
import { BigNumber } from "ethers";
import { useMemo, useRef } from "react";
import { JackpotBuyer } from "../hooks/contracts/jackpot";
import { EmptyState } from "../portion-box/EmptyState";
import { UserPurchasedRow } from "./UserPurchasedRow";

type Props = {
  buyers: JackpotBuyer[];
};

export type Buyer = {
  player: string;
  timestamp: BigNumber;
  number: BigNumber;
};

export const JackpotRoundTicketList = ({ buyers }: Props) => {
  const flatNumberBuyers = useMemo(() => {
    return buyers.flatMap((buyer) => buyer.number.map((number) => ({ ...buyer, number })));
  }, [buyers]);

  const buyerLength = useMemo(() => flatNumberBuyers.length, [flatNumberBuyers]);

  // The scrollable element for your list
  const parentRef = useRef<HTMLDivElement>(null);

  // The virtualizer
  const rowVirtualizer = useVirtualizer({
    count: buyerLength,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 34,
    overscan: 30,
  });

  return (
    <div ref={parentRef} className="h-[550px] overflow-y-auto pb-4">
      {buyers.length > 0 ? (
        <div>
          <div className="grid items-center grid-cols-12 p-4 md:px-12 gap-y-4">
            <div className="col-span-3 text-sm font-light text-white/50">User</div>
            <div className="col-span-3 text-sm font-light text-white/50">Time</div>
            <div className="col-span-6 text-sm font-light text-center text-white/50">Numbers</div>
          </div>
          <div
            className="relative"
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualItem) => (
              <div
                key={virtualItem.key}
                className="absolute top-0 left-0 grid items-center w-full grid-cols-12 p-4 md:px-12 gap-y-4"
                style={{
                  height: `${virtualItem.size}px`,
                  transform: `translateY(${virtualItem.start}px)`,
                }}
              >
                <UserPurchasedRow
                  digits={5}
                  number={flatNumberBuyers[virtualItem.index].number}
                  time={flatNumberBuyers[virtualItem.index].transactionAt}
                  user={flatNumberBuyers[virtualItem.index].user}
                  jackpot
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <EmptyState className="h-full p-12" />
      )}
    </div>
  );
};
