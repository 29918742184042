import { BigNumber, utils } from "ethers";
import { useMemo } from "react";
import useSWRInfinite from "swr/infinite";
import { config } from "../../../config";
import { findWinningPrize } from "../../../decimal-jackpot/utils/gameWinning";
import { fetcher } from "../../useRequest";
import { useJackpotWatchEvent } from "./useJackpotWatchEvent";
import { useRounds } from "./useRounds";
import { useJackpotTicket } from "../../../context/JackpotTicketContext";

type Player = {
  user: string;
  roundId: number;
  number: number;
  transactionAt: string;
};

type Play = {
  number: number;
  round: number;
  transactionAt: string;
  status: "WAITING" | "WIN" | "LOSE";
  highlightCount: number;
  winGame?: string;
  prize?: number;
};

const baseURL =
  process.env.REACT_APP_BASE_API || "https://billionbox-api-bhi6qpdeoa-as.a.run.app/api/v1";

const getKey =
  (account?: string, address?: string) => (pageIndex: number, previousPageData: any[]) => {
    if (previousPageData && !previousPageData.length) return null; // reached the end
    if (!account) return null;
    return `${baseURL}/jackpot/${account}/buyers?page=${pageIndex + 1}&contract=${address}`;
  };

export function usePlayerNumbers(account?: string) {
  const jackpotTicket = useJackpotTicket();
  const { data, ...rest } = useSWRInfinite<Player[]>(
    getKey(account, jackpotTicket.contractAddress),
    fetcher,
    {
      initialSize: 1,
      refreshInterval: 15000,
    }
  );

  const isReachEnded = useMemo(() => {
    if (data === undefined) return false;
    return data[data.length - 1]?.length === 0;
  }, [data]);

  const plays = useMemo(() => data?.flat(), [data]);

  const playRoundIds = useMemo(() => {
    const roundIds = new Set<number>();
    plays?.forEach((play) => {
      roundIds.add(play.roundId);
    });
    return Array.from(roundIds);
  }, [plays]);

  const { rounds, contractRead } = useRounds(playRoundIds);
  const roundsMap = useMemo(() => {
    const map = new Map<number, number>();

    if (rounds) {
      rounds.forEach((round) => {
        if (round.executedTime.gt(0)) {
          map.set(round.roundId.toNumber(), round.answer.toNumber());
        }
      });
    }

    return map;
  }, [rounds]);

  const playerNumbers = useMemo(() => {
    if (plays === undefined) return [];
    const _playNumbers: Play[] = [];

    for (const play of plays) {
      const amountPerNumber = config.jackpotPrice(jackpotTicket.currentTicket);
      const number = play.number;
      const roundAnswer = roundsMap.get(play.roundId);
      const _playNumber: Play = {
        number,
        transactionAt: play.transactionAt,
        highlightCount: 5,
        round: play.roundId,
        status: "WAITING",
      };

      if (roundAnswer !== undefined) {
        const prize = findWinningPrize(
          {
            amount: BigNumber.from(
              utils.parseUnits(amountPerNumber.toString(), config.stablecoinDecimals)
            ),
            number: BigNumber.from(number),
            claimed: false,
          },
          roundAnswer,
          true
        );

        _playNumber.status = prize.win ? "WIN" : "LOSE";
        _playNumber.highlightCount = prize.game?.highlightCount ?? 0;
        _playNumber.winGame = prize.game?.title;
        _playNumber.prize = prize.prize;
      }

      _playNumbers.push(_playNumber);
    }

    return _playNumbers;
  }, [plays, roundsMap, jackpotTicket.currentTicket]);

  useJackpotWatchEvent(() => {
    contractRead.refetch();
  });

  return { data: playerNumbers, isReachEnded, ...rest };
}
