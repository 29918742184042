import { Chain } from "wagmi";
import { Tab } from "./prediction/SelectAsset";
import { JackpotTicket } from "./context/JackpotTicketContext";

export type Asset = "btc" | "eth" | "matic" | "bnb";
export type JackpotAsset = "btc" | "eth" | "bnb";

export const bscChain: Chain = {
  id: 56,
  name: "BNB Smart Chain",
  network: "bsc",
  nativeCurrency: {
    decimals: 18,
    name: "BNB",
    symbol: "BNB",
  },
  rpcUrls: {
    default: "https://rpc.ankr.com/bsc",
    public: "https://rpc.ankr.com/bsc",
  },
  blockExplorers: {
    etherscan: { name: "BscScan", url: "https://bscscan.com" },
    default: { name: "BscScan", url: "https://bscscan.com" },
  },
  multicall: {
    address: "0xca11bde05977b3631167028862be2a173976ca11",
    blockCreated: 15921452,
  },
};

export const assetPairs = {
  btc: "BTCUSDT",
  eth: "ETHUSDT",
  bnb: "BNBUSDT",
  matic: "MATICUSDT",
};

export const address = {
  AGGREGATOR: {
    // btc: "0x007A22900a3B98143368Bd5906f8E17e9867581b",
    btc: "0xc907E116054Ad103354f2D350FD2514433D57F6f",
    eth: "0xF9680D99D6C9589e2a93a78A04A279e509205945",
    // matic: "0xAB594600376Ec9fD91F8e885dADF0CE036862dE0",
    matic: "0xd0D5e3DB44DE05E9F294BB0a3bEEaF030DE24Ada",
    bnb: "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE",
  },
  PORTION_BOX1: "0xb048b611b7315A2CF4ceb6530dCC6F768277fEa9",
  PORTION_BOX2: "0xb048b611b7315A2CF4ceb6530dCC6F768277fEa9",
  PORTION_BOX3: "0x5A5dC39c940744baaEd1f8974f8eE7C8F897c8c5",
  PORTION_BOX4: "0x9c66F5147Eb2A66Fca20991b2EBf662BD566E3Db",
  PORTION_BOX5: "0x67FaF6A191d75b8d253031876f088Df6248DA4f1",
  TICKET: "0x33f93BcDC64227E30C9cEEb2aAE254c16034e853",
  // TEST TOKEN
  USDT_ADDRESS: "0x55d398326f99059ff775485246999027b3197955",
  // USDT_ADDRESS: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
  BUSD_ADDRESS: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
  USDC_ADDRESS: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
  DAI_ADDRESS: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
  BUY_TOKEN_ADDRESS: "0x92012e414293888de15ee54da2bc576ed13d4fe5",
  DBUSD_ADDRESS: "0x414F40dC0e3feA0E2197cF833a621D2B251B18ca",
  BBOX_ADDRESS: "0xF35Fddd1B23de477B64C5D8119E5135C2A575D84",
  ALTERNATE_TOKEN_ADDRESS: "0x8b12564c088E3A7BC5DFa64b7209E12A775fad20",
  WETH_ADDRESS: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  ROUTER_ADDRESS: "0xa5e0829caced8ffdd4de3c43696c57f7d7a678ff",
  // BB MAX
  BB_MAX_ADDRESS: "0xfC212f0a363957E890e71CdF6bcCA4f2baF790cF",
  BB_MAX_LOTTERY_ADDRESS: "0x8E0D184Cc7CDec93b18fb5439fC03d20147489a4",
  BB_MAX_ONE_DIGIT_ADDRESS: "0x47e71bC1Cd02c810bafd55F8bC3c6C36FBA90052",
  // JACKPOT
  MATCH_1_GAME: "0x3928FB4c5582D4B07BAE6Cba34fB433270c3CB2C",
  MATCH_2_GAME: "0x25385C137B6D11256672D1E9f9cABb08A8b37250",
  MATCH_3_GAME: "0x0a5B92EB843AC449cAa3219d61B227853D4dFd99",
  MATCH_4_GAME: "0x5BBcBC311643fe531C811dAaAAc0FF72Ad248d4F",
  MATCH_5_GAME: "0xc4C520175313cEAaDF0B5A2972D86004A02326AD",
  SHUFFLE_4_GAME: "0x04490A8A77DC33c657F99e3FD5f859Dce01E113f",
  SHUFFLE_3_GAME: "0x6D3386216DC314FAc6317Be91654719E619F1A9a",
  JACKPOT_ADDRESS: "0xF84c47293a4d2ceC268AfcFDCBDCAb9F625eC32c",
  JACKPOT_BBOX_ADDRESS: "0x0689Bb35381b0586dA26d2Cf903E82F253d2d24d",
};

export const jackpotGames = [
  address.MATCH_1_GAME,
  address.MATCH_2_GAME,
  address.MATCH_3_GAME,
  address.MATCH_4_GAME,
  address.MATCH_5_GAME,
  address.SHUFFLE_4_GAME,
  address.SHUFFLE_3_GAME,
];

export const predictionAddr: Record<Tab, string> = {
  BTC: "0x2c9671D0D3F81C4095706D27fc7C921d9FD7E292",
  ETH: "0xe152216654e02fbbe756b4556304042b583fea8b",
  MATIC: "0x43A71c7c28D9322520E1F06a9D2062C3484E775c",
};

export const predictionProAddr = {
  "high-low": {
    BTC: "0x4082C3cF1F866BFd75E544181ad9D1377BbF0E7A",
    ETH: "0x4082C3cF1F866BFd75E544181ad9D1377BbF0E7A",
    MATIC: "0x4082C3cF1F866BFd75E544181ad9D1377BbF0E7A",
  },
  "up-down": {
    BTC: "0x4082C3cF1F866BFd75E544181ad9D1377BbF0E7A",
    ETH: "0x4082C3cF1F866BFd75E544181ad9D1377BbF0E7A",
    MATIC: "0x4082C3cF1F866BFd75E544181ad9D1377BbF0E7A",
  },
  "even-odd": {
    BTC: "0x4082C3cF1F866BFd75E544181ad9D1377BbF0E7A",
    ETH: "0x4082C3cF1F866BFd75E544181ad9D1377BbF0E7A",
    MATIC: "0x4082C3cF1F866BFd75E544181ad9D1377BbF0E7A",
  },
};

export const tokenAddr: Record<Tab, string> = {
  BTC: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
  ETH: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
  MATIC: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
};

export const EACAggregatorProxyAddr: Record<Tab, string> = {
  BTC: "0x007A22900a3B98143368Bd5906f8E17e9867581b",
  // BTC: "0xc907E116054Ad103354f2D350FD2514433D57F6f",
  ETH: "0xF9680D99D6C9589e2a93a78A04A279e509205945",
  MATIC: "0xAB594600376Ec9fD91F8e885dADF0CE036862dE0",
};

export type RoomDigit = 1 | 2 | 3 | 4 | 5 | number | string;
export const digits: RoomDigit[] = [5];

export const roomAddress: Record<string | number, string> = {
  1: address.PORTION_BOX1,
  2: address.PORTION_BOX2,
  3: address.PORTION_BOX3,
  4: address.PORTION_BOX4,
  5: address.PORTION_BOX5,
};

export const maxPrize: Record<RoomDigit, number> = {
  1: 10000000,
  2: 10000000,
  3: 10000000,
  4: 10000000,
  5: 10000000,
};

export const chainlinkAssetLink = {
  btc: "https://data.chain.link/polygon/mainnet/crypto-usd/btc-usd",
  eth: "https://data.chain.link/polygon/mainnet/crypto-usd/eth-usd",
  matic: "https://data.chain.link/polygon/mainnet/crypto-usd/matic-usd",
  bnb: "https://data.chain.link/bsc/mainnet/crypto-usd/bnb-usd",
} as const;

export const BB_MAX_GAMES: Record<number, string> = {
  1: address.BB_MAX_ONE_DIGIT_ADDRESS,
  2: address.BB_MAX_LOTTERY_ADDRESS,
};

export const predictionProGameContractName = {
  "even-odd": "EVEN_ODD",
  "high-low": "HIGH_LOW",
  "up-down": "UP_DOWN",
};

export const config = {
  bscChain,
  chain: bscChain,
  predictionProPayout: 1.9,
  predictionProLockSeconds: 60,
  predictionMaxAmount: 500,
  predictionRoundTime: {
    BTC: 60 * 15,
    ETH: 60 * 60,
    MATIC: 60 * 60,
    pro: {
      BTC: 60 * 60,
      ETH: 60 * 15,
      MATIC: 60 * 15,
    },
  },
  ticketPrice(room: RoomDigit) {
    return 5;
  },
  maxSizePerNumber: 99999,
  maxNumberPerTx(room: RoomDigit) {
    if (room == 1) return 10;
    return 100;
  },
  refetchInterval: 15_000,
  binancePriceRefetchInterval: 100,
  cacheTime: 10_000,
  shortCacheTime: 5_000,
  explorerUrl: "https://bscscan.com",
  stablecoinDecimals: 18,
  multiplierBox: {
    feePercent: 0,
    maxDecimalPlaces: 0,
    maxPerNumber: {
      [BB_MAX_GAMES[1]]: 500,
      [BB_MAX_GAMES[2]]: 100,
    } as Record<string, number>,
    maxPerRound: {
      [BB_MAX_GAMES[1]]: 5000,
      [BB_MAX_GAMES[2]]: 10000,
    } as Record<string, number>,
  },
  binancePriceUrl: "https://api.binance.com/api/v3/ticker/price",
  binanceKlinesUrl: "https://data.binance.com/api/v3/klines",
  jackpotPrice(ticket: JackpotTicket) {
    if (ticket === "bbox") {
      return 100000;
    } else {
      return 5;
    }
  },
  jackpotPayout: {
    [address.MATCH_1_GAME]: 100,
    [address.MATCH_2_GAME]: 2000,
    [address.MATCH_3_GAME]: 10000,
    [address.MATCH_4_GAME]: 60000,
    [address.MATCH_5_GAME]: 1000000,
    [address.SHUFFLE_4_GAME]: 400,
    [address.SHUFFLE_3_GAME]: 200,
  },
  jackpotBBOXPayout: {
    [address.MATCH_1_GAME]: 100,
    [address.MATCH_2_GAME]: 2000,
    [address.MATCH_3_GAME]: 10000,
    [address.MATCH_4_GAME]: 60000,
    [address.MATCH_5_GAME]: 1500000,
    [address.SHUFFLE_4_GAME]: 400,
    [address.SHUFFLE_3_GAME]: 200,
  },
};

export const JACKPOT_GAME_TITLES = {
  [address.MATCH_1_GAME]: "Match First 1",
  [address.MATCH_2_GAME]: "Match First 2",
  [address.MATCH_3_GAME]: "Match First 3",
  [address.MATCH_4_GAME]: "Match First 4",
  [address.MATCH_5_GAME]: "Jackpot",
  [address.SHUFFLE_4_GAME]: "Shuffle 4",
  [address.SHUFFLE_3_GAME]: "Shuffle 3",
};

export const JACKPOT_GAME_TITLES_FULL = {
  [address.MATCH_1_GAME]: "Match First 1",
  [address.MATCH_2_GAME]: "Match First 2",
  [address.MATCH_3_GAME]: "Match First 3",
  [address.MATCH_4_GAME]: "Match First 4",
  [address.MATCH_5_GAME]: "Jackpot",
  [address.SHUFFLE_4_GAME]: "Shuffle First 4",
  [address.SHUFFLE_3_GAME]: "Shuffle First 3",
};

export const JACKPOT_GAMES_NUMBER_HIGHLIGHT = {
  [address.MATCH_1_GAME]: 1,
  [address.MATCH_2_GAME]: 2,
  [address.MATCH_3_GAME]: 3,
  [address.MATCH_4_GAME]: 4,
  [address.MATCH_5_GAME]: 5,
  [address.SHUFFLE_4_GAME]: 4,
  [address.SHUFFLE_3_GAME]: 3,
};
