import clsx from "clsx";
import { memo, useMemo } from "react";
import { compactAddress } from "../utils/formatter";
import { JackpotPriceNumbers } from "./JackpotPriceNumbers";
import { NumberCircleCard } from "./NumberCircleCard";

type UserPurchasedRowProps = {
  user: string;
  time: string;
  number: string | number;
  size?: string | number;
  digits: number;
  jackpot?: boolean;
};

const units: any = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
};

const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

const getRelativeTime = (d1: Date, d2 = new Date()) => {
  var elapsed = d1.getTime() - d2.getTime();

  for (var u in units)
    if (Math.abs(elapsed) > units[u] || u === "second") {
      return rtf.format(Math.round(elapsed / units[u]), u as any);
    }
};

export const UserPurchasedRow = memo(
  ({ user, time, number, size, digits, jackpot }: UserPurchasedRowProps) => {
    const shortenUser = useMemo(() => compactAddress(user), [user]);
    const formattedTime = useMemo(() => {
      const date = Number.isNaN(Number(time)) ? new Date(time) : new Date(Number(time));
      return getRelativeTime(date);
    }, [time]);

    return (
      <>
        <div className="col-span-3 text-sm font-semibold">{shortenUser}</div>
        <div className="col-span-3 text-sm font-light text-white/70">{formattedTime}</div>
        <div
          className={clsx(
            "flex items-center col-span-6 font-light",
            jackpot && "lg:pl-4 md:pl-8 sm:pl-8 pl-4"
          )}
        >
          {!jackpot &&
            number
              .toString()
              .padStart(digits, "0")
              .split("")
              .map((number, index) => (
                <NumberCircleCard
                  key={index}
                  number={Number(number)}
                  className="lg:w-[25px] w-[22px] lg:h-[25px] h-[22px] lg:text-lg text-sm"
                  borderWidth={2}
                />
              ))}

          {jackpot && (
            <>
              <JackpotPriceNumbers
                className="text-base md:mx-[4px] mx-[2px] w-7 border-[2px]"
                number={Number(number)}
              />
            </>
          )}
          {!size ? <></> : <span className="ml-2 font-bold text-gold">x{size}</span>}
        </div>
      </>
    );
  }
);
