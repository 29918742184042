import { memo, useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Popup from "reactjs-popup";
import ChainlinkWhite from "../assets/chainlink-white.svg";
import InfoIcon from "../assets/info-black-icon.svg";
import AssetBTC from "../assets/prediction/btc.png";
import AssetETH from "../assets/prediction/eth.png";
import AssetMatic from "../assets/prediction/matic.png";
import { Loading } from "../common/Loading";
import { TabGroup } from "../common/TabGroup";
import { ToggleGraphSwitch } from "../common/ToggleGraphSwitch";
import { Asset, chainlinkAssetLink, config } from "../config";
import { useChainlinkPrice } from "../hooks/contracts/useChainlinkPrice";
import { useRequest } from "../hooks/useRequest";
import useWindowDimensions from "../hooks/useWindowDimensions";

const assetIcons = {
  btc: AssetBTC,
  eth: AssetETH,
  matic: AssetMatic,
  bnb: AssetMatic,
};

type Props = {
  asset: Asset;
};

type Timeframe = {
  timeframe: string;
  prices: number[];
  open: number;
  end: number;
  high: number;
  low: number;
};

type PriceResponse = {
  timeframes: Timeframe[];
};

export const GraphPriceInfo = (props: { asset: Asset }) => {
  return (
    <div className="bg-black">
      <Graph asset={props.asset} />
    </div>
  );
};

export const Graph = memo(({ asset }: Props) => {
  const { width } = useWindowDimensions();
  const [activeTab, setActiveTab] = useState(0);
  const [showGraph, setShowGraph] = useState(true);

  const activeTimeframeMinute = useMemo(() => {
    const timeframeMinutes = [1, 5, 15, 30, 60];
    return timeframeMinutes[activeTab];
  }, [activeTab]);

  const [timeframes, setTimeframes] = useState<Timeframe[]>([]);
  const { latest } = useChainlinkPrice(asset, {
    formattedDecimalPlaces: asset === "matic" ? 4 : 2,
  });

  const { data } = useRequest<PriceResponse>({
    path: `/price/${asset}?timeframe=${activeTimeframeMinute}`,
    config: {
      refreshInterval: 20_000,
    },
  });

  const timeframeChartSeries = useMemo(() => getChartSeries(timeframes, width), [timeframes]);

  const chartKey = useMemo(() => {
    if (timeframes.length > 0) return timeframes[timeframes.length - 1].timeframe;
  }, [timeframes]);

  useEffect(() => {
    setTimeframes(data?.timeframes ?? []);
  }, [data]);

  return (
    <>
      <div className="h-[2px] bg-gold mb-8"></div>
      <div className="flex justify-center -mt-16">
        <div className="relative z-10 px-4 py-5 mx-auto overflow-hidden bg-cover rounded-2xl border-gradient-gold-lg w-[265px] lg:w-[400px]">
          <div className="absolute inset-0 z-0 bg-cover bg-graph-toggle"></div>
          <div className="relative flex flex-row items-center space-x-3">
            <img
              alt=""
              className="object-contain h-4"
              src={require("../assets/chart-icon@2x.webp")}
            />
            <span className="font-bold lg:text-xl">CHART</span>
            <ToggleGraphSwitch checked={showGraph} onCheckChanged={setShowGraph} />
          </div>
        </div>
      </div>
      <div className={!showGraph ? "hidden" : ""} id="chart">
        <div className="relative flex flex-col items-center justify-between px-8 lg:items-end lg:flex-row">
          <div className="mt-8 lg:mt-0">
            <div className="flex items-center justify-center">
              <img className="w-8" alt="" src={assetIcons[asset]} />
              <span className="ml-2 font-medium">{asset.toUpperCase()} PRICE</span>
              <Popup
                trigger={
                  <img className="w-4 ml-2" src={require("../assets/icon/info1.png")} alt="" />
                }
                on="hover"
                position="bottom center"
                mouseEnterDelay={0}
                contentStyle={{
                  border: 0,
                  backgroundColor: "#faf6a6",
                  backgroundImage: "linear-gradient(180deg, #faf6a6 0%, #c5a44e 100%)",
                }}
                arrowStyle={{ color: "#faf6a6", strokeWidth: 0 }}
              >
                <div className="px-[6px] py-1">
                  <div className="flex items-start space-x-2">
                    <img alt="delay info" className="w-4 mt-[1px]" src={InfoIcon} />
                    <div className="text-black">
                      <div className="text-sm font-bold">
                        Chainlink oracles are currently displaying charts
                      </div>
                      <div className="text-[11px] leading-tight">
                        The prediction game is powered by the Chainlink oracle, and that's how you
                        see the price (Every {config.refetchInterval / 1000} seconds, Oracle prices
                        refresh.)
                      </div>
                    </div>
                  </div>
                </div>
              </Popup>
            </div>
            <div className="text-3xl font-bold text-center">${latest?.formattedAnswer}</div>
          </div>
          <TabGroup
            tabs={["1 min", "5 min", "15 min", "30 min", "60 min"]}
            className="mt-6 text-[9px] lg:text-sm lg:mt-0"
            buttonClassName="px-3"
            onTabChange={setActiveTab}
          />
          <a
            href={chainlinkAssetLink[asset]}
            className="px-4 py-1 my-6 card-border-gradient-blue-to-yellow lg:my-0"
            target="_blank"
            rel="noreferrer"
          >
            View on <img alt="chainlink website" className="inline-block" src={ChainlinkWhite} />
          </a>
        </div>
        <div className="mt-6 p-4 bg-[#04132c] relative">
          {!data && <Loading />}
          <ReactApexChart
            key={chartKey}
            options={{
              grid: {
                borderColor: "rgba(255, 255, 255, 0.1)",
              },
              chart: {
                type: "candlestick",
                height: 300,
                toolbar: {
                  show: false,
                  autoSelected: "pan",
                },
                foreColor: "#fff",
              },
              xaxis: {
                type: "datetime",
                crosshairs: {
                  position: "back",
                  stroke: {
                    dashArray: 4,
                    color: "#e2d27f",
                  },
                },
                tickPlacement: "on",
                labels: {
                  format: "HH:mm",
                  datetimeUTC: false,
                },
              },
              yaxis: {
                labels: {
                  offsetX: -10,
                },
                tooltip: {
                  enabled: false,
                },
                tickAmount: 8,
              },
              tooltip: {
                followCursor: true,
                custom: ({ dataPointIndex }) => {
                  const timeframeData = timeframeChartSeries[dataPointIndex];
                  return `
                  <div class="px-1">
                    <div>$${timeframeData.prices[timeframeData.prices.length - 1].toFixed(5)}
                  </div>
                `;
                },
              },
            }}
            series={[{ data: timeframeChartSeries }]}
            type="candlestick"
            height={360}
          />
        </div>
        <div className="h-[2px] bg-gold"></div>
      </div>
    </>
  );
});

function getChartSeries(timeframes: Timeframe[], width: number | null) {
  const series = timeframes.map((timeframe) => ({
    x: new Date(timeframe.timeframe).getTime(),
    y: [
      timeframe.open.toFixed(2),
      timeframe.high.toFixed(2),
      timeframe.low.toFixed(2),
      timeframe.end.toFixed(2),
    ],
    ...timeframe,
  }));

  if (width && width < 1100) {
    return series.slice(Math.floor(series.length * 0.6));
  } else {
    return series;
  }
}

function getMinZoomTime(timeframes: Timeframe[], width: number | null) {
  const timeframeLength = timeframes.length;

  if (timeframeLength < 8 || (width && width < 1100)) {
    return;
  }

  return new Date(timeframes[Math.floor(timeframeLength * 0.8)].timeframe).getTime();
}
