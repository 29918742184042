import { BigNumber, BigNumberish } from "ethers";
import { useMemo } from "react";
import { useContractRead } from "wagmi";
import { address, config } from "../../../config";
import Jackpot from "../../../contracts/Jackpot.json";
import { useJackpotTicket } from "../../../context/JackpotTicketContext";

type ReadFns =
  | "currentRoundId"
  | "rounds"
  | "roundAmounts"
  | "getPayoutRate"
  | "getRoundNumbers"
  | "getRoundNumberLength"
  | "getRounds"
  | "getPlayInfosByRoundIds"
  | "getPlayInfos"
  | "getPositions"
  | "getRoundAnswers"
  | "isNumbersWinning";

// prettier-ignore
type ReadArgs<Fn> =
  Fn extends "currentRoundId" ? undefined :
  Fn extends "rounds" ? BigNumberish :
  Fn extends "getPayoutRate" ? [BigNumberish | undefined, string] :
  Fn extends "getRoundNumbers" ? [roundId: BigNumberish | undefined, cursor: BigNumberish, limit: BigNumberish]:
  Fn extends "getRoundNumberLength" ? BigNumberish :
  Fn extends "getRounds" ? [roundIds: BigNumberish[]] :
  Fn extends "getPlayInfosByRoundIds" ? [owner: string, roundIds: BigNumberish[], cursor: BigNumberish, limit: BigNumberish] :
  Fn extends "getPlayInfos" ? [string, BigNumberish, BigNumberish, BigNumberish] :
  Fn extends "getPositions" ? string :
  Fn extends "getRoundAnswers" ? BigNumberish :
  Fn extends "isNumbersWinning" ? [games: string[], roundIds: BigNumberish[], numbers: BigNumberish[]] :
  never;

export type RoundNumber = {
  number: BigNumber;
  player: string;
  timestamp: BigNumber;
};

// prettier-ignore
type ReadResult<Fn> =
  Fn extends "currentRoundId" ? BigNumber :
  Fn extends "rounds" ? JackpotRound :
  Fn extends "roundAmounts" ? BigNumber :
  Fn extends "getPayoutRate" ? BigNumber :
  Fn extends "getRoundNumbers" ? RoundNumber[] :
  Fn extends "getRoundNumberLength" ? BigNumber :
  Fn extends "getRounds" ? JackpotRoundGameInfo[] :
  Fn extends "getPlayInfosByRoundIds" ? PlayInfo[][] :
  Fn extends "getPlayInfos" ? PlayInfo[] :
  Fn extends "getPositions" ? Position[] :
  Fn extends "getRoundAnswers" ? { game: string[], answers: BigNumber[][], payouts: BigNumber[] } :
  Fn extends "isNumbersWinning" ? { result: boolean[], payouts: BigNumber[] }[] :
  never;

type FnObject<Fn extends ReadFns> = {
  functionName: Fn;
  enabled?: boolean;
  args?: ReadArgs<Fn>;
  watch?: boolean;
};

export type JackpotRound = {
  roundId: BigNumber;
  startTime: BigNumber;
  closeTime: BigNumber;
  executeAfterTime: BigNumber;
  executedTime: BigNumber;
  answer: BigNumber;
  answerPrice: BigNumber;
  answerTime: BigNumber;
};

export type JackpotRoundGameInfo = {
  roundId: BigNumber;
  startTime: BigNumber;
  closeTime: BigNumber;
  executeAfterTime: BigNumber;
  executedTime: BigNumber;
  answer: BigNumber;
  answerPrice: BigNumber;
  answerTime: BigNumber;
};

export type MultiplierBoxFinishedRound = {
  roundId: BigNumber;
  startTime: BigNumber;
  closeTime: BigNumber;
  executeAfterTime: BigNumber;
  executedTime: BigNumber;
  answer: BigNumber;
  answerPrice: BigNumber;
  answerTime: BigNumber;
  payout: BigNumber;
};

export type PlayInfo = {
  amount: BigNumber;
  number: BigNumber;
  claimed: boolean;
};

export type Position = {
  roundId: BigNumber;
};

export function useJackpotRead<ReadFn extends ReadFns>({
  functionName,
  args,
  watch = false,
  enabled = true,
}: FnObject<ReadFn>) {
  const jackpotTicket = useJackpotTicket();
  const contractRead = useContractRead({
    args,
    watch,
    enabled,
    functionName,
    addressOrName: jackpotTicket.contractAddress,
    contractInterface: Jackpot,
    staleTime: config.shortCacheTime,
    cacheTime: config.shortCacheTime,
  });

  const data: ReadResult<ReadFn> | undefined = useMemo(() => {
    if (!contractRead.data) return;
    return contractRead.data as unknown as ReadResult<ReadFn> | undefined;
  }, [contractRead.data]);

  return {
    contractRead,
    data,
  };
}
