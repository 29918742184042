import clsx from "clsx";
import BinanceLogo from "../assets/multiplier-box/binance-logo.svg";
import { JackpotAsset } from "../config";
import { useAssetPriceStore } from "../store/asset-price";
import { getDecimals } from "../utils/decimal";
import TradingViewGraph from "./TradingViewGraph";
import { Skeleton } from "./Skeleton";

type Props = {
  asset: JackpotAsset;
};

const pairs = {
  btc: {
    name: "BTC / USDT",
    icon: require("../assets/icon/btc@2x.png"),
    assetSymbol: "BTC_USDT",
    border: "border-btc",
    digits: 2,
  },
  eth: {
    name: "ETH / USDT",
    icon: require("../assets/icon/eth.png"),
    assetSymbol: "ETH_USDT",
    border: "border-eth",
    digits: 2,
  },
  bnb: {
    name: "BNB / USDT",
    icon: require("../assets/icon/bnb.png"),
    assetSymbol: "BNB_USDT",
    border: "border-bnb",
    digits: 1,
  },
};

export function GraphWithPriceCard({ asset }: Props) {
  return (
    <div
      className={clsx(
        "flex flex-col items-center p-4 border rounded-xl gap-y-2 bg-gradient-black-to-blue-r",
        pairs[asset].border
      )}
    >
      <div className="flex items-center w-full px-3 gap-x-2 font-roboto-condensed">
        <img alt="BTC" className="w-8" src={pairs[asset].icon} />
        <div className="flex-1">{pairs[asset].name}</div>
        <Price asset={asset} />
      </div>
      <div className="flex-1 w-full py-2">
        <TradingViewGraph asset={asset} timeframe={1} />
      </div>
      <a
        href={`https://www.binance.com/en/trade/${pairs[asset].assetSymbol}`}
        target="_blank"
        rel="noreferrer"
        className="flex flex-row items-center px-4 py-2 space-x-2 bg-black border border-gray-600 rounded-3xl"
      >
        <span className="text-sm">View on</span>
        <img className="h-[12px]" src={BinanceLogo} alt="" />
      </a>
    </div>
  );
}

function Price({ asset }: { asset: JackpotAsset }) {
  const price = useAssetPriceStore((s) => s.price);

  return (
    <div className="text-2xl font-bold text-gradient gold">
      {!price[asset] ? (
        <Skeleton containerClassName="w-[90px]" className="h-[23px]" />
      ) : (
        <>
          $
          {price[asset]?.toLocaleString("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
          .
          <span
            className={`inline-block w-11 py-[1px] ml-1 text-center border rounded-xl text-gradient gold border-${asset}`}
          >
            {getDecimals(pairs[asset].digits, price[asset])
              .toString()
              .padStart(pairs[asset].digits, "0")}
          </span>
        </>
      )}
    </div>
  );
}
