import clsx from "clsx";
import { useMemo } from "react";
import "reactjs-popup/dist/index.css";
import { JACKPOT_GAME_TITLES_FULL, address, config } from "../config";
import { JackpotTicket, useJackpotTicket } from "../context/JackpotTicketContext";
import { useTicketIcon } from "../hooks/contracts/jackpot/useTicketIcon";

export function Condition() {
  const jackpotTicket = useJackpotTicket();
  const ticketIcon = useTicketIcon();

  const payout = useMemo(() => {
    return jackpotTicket.currentTicket === "usdt" ? config.jackpotPayout : config.jackpotBBOXPayout;
  }, [jackpotTicket.currentTicket]);

  const ticketPrice = useMemo(
    () => config.jackpotPrice(jackpotTicket.currentTicket),
    [jackpotTicket.currentTicket]
  );

  return (
    <div className="relative flex flex-col w-full col-span-2 py-3 px-4 bg-blue-gray rounded-2xl border border-[#ffffff15] font-roboto-condensed text-sm">
      <img
        alt=""
        className="absolute bottom-0 z-0 mx-auto w-52 right-5"
        src={require("../assets/cube-trans9.png")}
      />
      <div className="text-lg font-bold text-center text-gradient gold">WINNING CONDITION</div>

      <PrizeRow
        ticket={jackpotTicket.currentTicket}
        jackpot
        gameAddress={address.MATCH_5_GAME}
        payout={payout}
        ticketIcon={ticketIcon}
        ticketPrice={ticketPrice}
        renderMatchIcon={() => (
          <>
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/win@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/win@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/win@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/win@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/win@2x.png")}
              alt=""
            />
          </>
        )}
      />

      <PrizeRow
        ticket={jackpotTicket.currentTicket}
        gameAddress={address.MATCH_4_GAME}
        payout={payout}
        ticketIcon={ticketIcon}
        ticketPrice={ticketPrice}
        renderMatchIcon={() => (
          <>
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/win@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/win@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/win@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/win@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/dash@2x.png")}
              alt=""
            />
          </>
        )}
      />

      <PrizeRow
        ticket={jackpotTicket.currentTicket}
        gameAddress={address.MATCH_3_GAME}
        payout={payout}
        ticketIcon={ticketIcon}
        ticketPrice={ticketPrice}
        renderMatchIcon={() => (
          <>
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/win@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/win@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/win@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/dash@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/dash@2x.png")}
              alt=""
            />
          </>
        )}
      />

      <PrizeRow
        ticket={jackpotTicket.currentTicket}
        gameAddress={address.MATCH_2_GAME}
        payout={payout}
        ticketIcon={ticketIcon}
        ticketPrice={ticketPrice}
        renderMatchIcon={() => (
          <>
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/win@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/win@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/dash@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/dash@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/dash@2x.png")}
              alt=""
            />
          </>
        )}
      />

      <PrizeRow
        ticket={jackpotTicket.currentTicket}
        gameAddress={address.MATCH_1_GAME}
        payout={payout}
        ticketIcon={ticketIcon}
        ticketPrice={ticketPrice}
        renderMatchIcon={() => (
          <>
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/win@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/dash@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/dash@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/dash@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/dash@2x.png")}
              alt=""
            />
          </>
        )}
      />

      <PrizeRow
        ticket={jackpotTicket.currentTicket}
        gameAddress={address.SHUFFLE_4_GAME}
        payout={payout}
        ticketIcon={ticketIcon}
        ticketPrice={ticketPrice}
        renderMatchIcon={() => (
          <>
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/shuffle@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/shuffle@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/shuffle@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/shuffle@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/dash@2x.png")}
              alt=""
            />
          </>
        )}
      />

      <PrizeRow
        ticket={jackpotTicket.currentTicket}
        gameAddress={address.SHUFFLE_3_GAME}
        payout={payout}
        ticketIcon={ticketIcon}
        ticketPrice={ticketPrice}
        renderMatchIcon={() => (
          <>
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/shuffle@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/shuffle@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/shuffle@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/dash@2x.png")}
              alt=""
            />
            <img
              className="w-[12px] aspect-square"
              src={require("../assets/icon/dash@2x.png")}
              alt=""
            />
          </>
        )}
      />

      <section className="z-10 mt-2 text-center">
        <div className="text-xs text-left opacity-60">* Winner will take only biggest prize</div>
        <a
          href="https://info.billionbox.io/products/lottery-on-chain-jackpot-game"
          target="_blank"
          className="inline-block mt-2 font-bold uppercase border-b border-bnb text-gradient gold hover:opacity-70"
          rel="noopener noreferrer"
        >
          Read more
        </a>
      </section>
    </div>
  );
}

type PrizeRowProps = {
  ticket: JackpotTicket;
  jackpot?: boolean;
  gameAddress: string;
  ticketIcon: any;
  ticketPrice: number;
  payout: Record<string, number>;
  renderMatchIcon: () => React.ReactNode;
};

function PrizeRow({
  ticket,
  jackpot,
  gameAddress,
  ticketIcon,
  ticketPrice,
  payout,
  renderMatchIcon,
}: PrizeRowProps) {
  return (
    <div className="flex items-center w-full mx-auto mt-[5px] gap-x-6">
      <div className="flex gap-x-1">{renderMatchIcon()}</div>
      <div className={clsx("w-[75px]", jackpot && "font-bold")}>
        {JACKPOT_GAME_TITLES_FULL[gameAddress]}
      </div>
      <div
        className={clsx(
          "flex items-center gap-x-1",
          ticket === "bbox" ? "w-[94px]" : "w-[60px]",
          jackpot && "text-gradient gold font-bold"
        )}
      >
        <img alt="ticket" className="w-4" src={ticketIcon} />{" "}
        {((ticketPrice * payout[gameAddress]) / 100).toLocaleString("en-US")}
      </div>
      <div className={clsx("flex items-center font-bold gap-x-1", jackpot && "text-gradient gold")}>
        {(payout[gameAddress] / 100).toLocaleString("en-US")}X
      </div>
    </div>
  );
}
