import { memo } from "react";
import { useAccount } from "wagmi";
import { Button, Skeleton, useNotificationContext } from "../common";
import { useJackpotWrite, useReward } from "../hooks/contracts/jackpot";

const _MyReward = () => {
  const { pushNotification } = useNotificationContext();
  const { address: account } = useAccount();

  const { reward, rewardRoundIds, gameAddresses, playInfoIndexes, refetch, pending } =
    useReward(account);

  const { writeAsync: claim } = useJackpotWrite("claimBySpecificInfo", [
    rewardRoundIds,
    playInfoIndexes,
    gameAddresses,
  ]);

  async function executeClaim() {
    const tx = await claim?.();

    pushNotification({
      key: tx.hash,
      type: "sendTx",
      txhash: tx.hash,
    });

    await tx.wait(1);

    refetch();

    pushNotification({
      key: Math.random().toString(),
      type: "success",
      text: "You've claimed the rewards!",
    });
  }

  return (
    <div className="lg:col-span-4">
      <div className="relative flex flex-col items-center justify-center h-full py-3 overflow-hidden gap-y-2 rounded-2xl bg-gradient-gold-max">
        <div className="text-center">
          <p className="font-roboto-condensed">Your Reward</p>
          <div className="text-3xl font-bold font-roboto-condensed">
            {pending ? (
              <Skeleton className="h-[20px] w-20 mt-2" />
            ) : (
              `$${reward.toLocaleString("en-US")}`
            )}
          </div>
        </div>
        <Button
          buttonSize="sm"
          className="z-10 px-6 font-bold uppercase"
          preset="white"
          disabled={reward <= 0 || pending}
          onClick={executeClaim}
        >
          Claim reward
        </Button>

        <img
          alt=""
          className="absolute bottom-0 left-0 w-36 rounded-bl-2xl"
          src={require("../assets/money-bag.png")}
        />
      </div>
    </div>
  );
};

export const MyReward = memo(_MyReward);
