import { useState } from "react";
import { Button } from "../common/Button";
import { WarningLimitModal } from "../common/WarningLimitModal";
import { config } from "../config";
import { EmptyState } from "../portion-box/EmptyState";
import { useNumberStore } from "../store/number-store";
import { BuyNumberModal } from "./BuyNumberModal";
import { useJackpotTicket } from "../context/JackpotTicketContext";

export function SelectedNumber() {
  const jackpotTicket = useJackpotTicket();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHurryOpen, setIsHurryOpen] = useState(false);
  const numberStore = useNumberStore();

  function openBuyModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
    setIsHurryOpen(false);
  }

  function deleteRow(index: number) {
    numberStore.deleteNumber(index);
  }

  function resetNumbers() {
    numberStore.clearNumbers();
  }

  return (
    <div className="lg:w-[700px] w-full lg:mx-auto relative container">
      <div className="card-border-gradient-gold-bg-black">
        <div className="p-4 rounded-t-lg bg-gradient-gold-max">
          <p className="text-2xl text-center text-white uppercase font-audiowide">
            Selected Number
          </p>
        </div>

        <div className="px-2 py-4 lg:container">
          {numberStore.numbers.length === 0 && (
            <EmptyState className="py-24">Start add number.</EmptyState>
          )}
          {numberStore.numbers.length > 0 && (
            <div className="flex items-center justify-between mt-3">
              <div className="space-x-1 text-xl">
                <span className="text-white/60">Total</span>
                <span className="font-bold text-white">
                  {numberStore.numbers.length} number{numberStore.numbers.length > 1 ? "s" : ""}
                </span>
              </div>
              <Button
                preset="gold-max-solid"
                className="flex items-center py-1 pl-3 pr-4 text-sm"
                onClick={resetNumbers}
              >
                <img
                  className="w-4 mr-2"
                  src={require("../assets/portion-box-room/delete.png")}
                  alt=""
                />
                RESET
              </Button>
            </div>
          )}
          <div className="grid md:grid-cols-3 grid-cols-2 gap-6 px-9 overflow-x-hidden max-h-[400px] no-scrollbar pt-10">
            {numberStore.numbers.map((number, index) => {
              return (
                <div className="relative py-4 text-center card-border-brown-bg-blue" key={index}>
                  <button className="absolute w-6 -top-3 -right-3" onClick={() => deleteRow(index)}>
                    <img src={require("../assets/portion-box-room/delete.png")} alt="" />
                  </button>

                  <p className="text-3xl font-bold tracking-widest text-gradient gold">
                    {number.number.toString().padStart(Number(5), "0")}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        {numberStore.numbers.length > 0 && (
          <div className="flex flex-col justify-between gap-4 p-6 lg:flex-row">
            <div className="flex items-center justify-center lg:justify-start">
              <img className="w-8 mr-5" src={require("../assets/icon/question@2x.png")} alt="" />
              <div>
                <p className="font-bold">Prize Condition</p>
                <p className="text-sm font-light">
                  1 Number = {config.jackpotPrice(jackpotTicket.currentTicket)}{" "}
                  {jackpotTicket.currentTicket.toUpperCase()}
                </p>
              </div>
            </div>

            <Button
              className="px-20 mt-5 uppercase lg:px-44 lg:mt-0"
              preset="goldMax"
              buttonSize="md"
              onClick={openBuyModal}
            >
              buy
            </Button>
          </div>
        )}
        {isModalOpen && <BuyNumberModal isOpen={isModalOpen} closeModal={closeModal} gold />}
        <WarningLimitModal isOpen={isHurryOpen} closeModal={closeModal} gold />
      </div>
    </div>
  );
}
