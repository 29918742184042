import React, { createContext, useContext, useMemo, useState } from "react";
import { address } from "../config";

export type JackpotTicket = "bbox" | "usdt";

type JackpotTicketState = {
  contractAddress: string;
  currentTicket: JackpotTicket;
  changeTicket: (asset: JackpotTicket) => void;
};

const JackpotTicketContext = createContext<JackpotTicketState>({
  contractAddress: "",
  currentTicket: "usdt",
  changeTicket(asset) {},
});

export const JackpotTicketProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentTicket, setCurrentTicket] = useState<JackpotTicket>(
    window.location.search.includes("ticket=usdt") ? "usdt" : "bbox"
  );

  const contractAddress = useMemo(
    () => (currentTicket === "bbox" ? address.JACKPOT_BBOX_ADDRESS : address.JACKPOT_ADDRESS),
    [currentTicket]
  );

  return (
    <JackpotTicketContext.Provider
      value={{ contractAddress, currentTicket, changeTicket: setCurrentTicket }}
    >
      {children}
    </JackpotTicketContext.Provider>
  );
};

export const useJackpotTicket = () => useContext(JackpotTicketContext);
