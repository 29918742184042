import { useBinanceSocketPrice } from "../hooks/useBinanceSocketPrice";
import { Skeleton } from "./Skeleton";

export const SmallBTCBinanceSocketPrice = () => {
  const price = useBinanceSocketPrice("btc");

  return (
    <div className="flex items-center mr-4">
      <img alt="" className="w-5 mr-1 lg:mr-2" src={require("../assets/icon/btc.png")} />
      {!price ? (
        <Skeleton className="w-[60px]" containerClassName="h-[20px]" />
      ) : (
        <span className="font-bold">
          $
          {price?.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      )}
    </div>
  );
};
