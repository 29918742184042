export function Banner() {
  return (
    <div className="relative bg-no-repeat bg-cover bg-jackpot">
      <div className="w-full h-24 bg-gradient-to-b from-black"></div>
      <div className="container relative z-10 pb-16 mt-8">
        <img
          className="max-w-full w-[600px] mx-auto"
          src={require("../assets/jackpot-title@3x.png")}
          alt=""
        />
        <p className="max-w-full w-[620px] -mt-7 text-center mx-auto lg:text-xl text-lg font-light">
          Join the fair and decentralized blockchain lottery.
        </p>
        <div className="flex flex-col items-center justify-center mt-9 md:space-x-5 md:flex-row">
          <div className="flex flex-wrap justify-center gap-2 lg:justify-start">
            <img className="h-12" src={require("../assets/secure-by-gold@2x.png")} alt=""></img>
            <img className="h-12" src={require("../assets/binance-blackwhite@2x.png")} alt=""></img>
            <img className="h-12" src={require("../assets/chainlink@2x.png")} alt=""></img>
          </div>

          <div className="flex mt-5 md:mt-0">
            <a
              href="https://info.billionbox.io/products/the-decimal-box/decimal-jackpot"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center mr-10"
            >
              <img className="h-5 mr-2" src={require("../assets/icon/question.png")} alt="" />
              <p className="text-base uppercase text-gradient gold">How to play</p>
            </a>
            <a
              href="https://info.billionbox.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center"
            >
              <img className="h-5 mr-2" src={require("../assets/icon/doc.png")} alt="" />
              <p className="text-base uppercase text-gradient gold">doc</p>
            </a>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 z-0 w-full h-56 bg-gradient-to-t from-black"></div>
    </div>
  );
}
