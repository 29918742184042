import { HTMLProps } from "react";
import { JackpotAsset } from "../config";

type NumberAssetCircleCardProp = HTMLProps<HTMLDivElement> & {
  number?: number | string;
  borderWidth?: number;
  icon?: boolean;
  asset: JackpotAsset;
};

const assetConfig = {
  btc: {
    border: "border-btc",
    icon: require("../assets/icon/btc.png"),
  },
  eth: {
    border: "border-eth",
    icon: require("../assets/icon/eth.png"),
  },
  bnb: {
    border: "border-bnb",
    icon: require("../assets/icon/bnb.png"),
  },
};

export function NumberAssetCircleCard({
  number,
  className,
  icon,
  borderWidth,
  children,
  asset,
  ...rest
}: NumberAssetCircleCardProp) {
  return (
    <div
      className={`mx-1 lg:mx-2 relative border-[3px] rounded-full aspect-square ${className} ${assetConfig[asset].border}`}
      style={{ borderWidth }}
      {...rest}
    >
      {icon && (
        <img
          src={assetConfig[asset].icon}
          alt="Asset"
          className="absolute right-0 md:w-6 w-5 top-[-15%] sm:top-[-10%] md:top-[-6%]"
        />
      )}
      <div className="absolute font-bold -translate-x-1/2 -translate-y-1/2 font-roboto-condensed top-1/2 left-1/2">
        {children ?? number}
      </div>
    </div>
  );
}
