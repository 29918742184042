import useSWR from "swr";
import { BareFetcher, PublicConfiguration } from "swr/dist/types";

export async function fetcher<JSON = any>(input: RequestInfo, init?: RequestInit): Promise<JSON> {
  const res = await fetch(input, init);
  return res.json();
}

type Params<JSON> = {
  path: string;
  enabled?: boolean;
  baseURL?: string;
  config?: Partial<PublicConfiguration<JSON, Error, BareFetcher<JSON>>>;
};

export function useRequest<JSON = any>({ path, baseURL, enabled = true, config }: Params<JSON>) {
  const defaultURL =
    process.env.REACT_APP_BASE_API || "https://billionbox-api-bhi6qpdeoa-as.a.run.app/api/v1";
  const usedBaseURL = baseURL || defaultURL;

  return useSWR<JSON, Error>(enabled && usedBaseURL + path, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    ...config,
  });
}
