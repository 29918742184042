import clsx from "clsx";
import { useMemo, useState } from "react";
import { Button } from "../common/Button";
import { NumberCircleCard } from "../common/NumberCircleCard";
import { config } from "../config";
import { useNumberReachMaxPerTx } from "../hooks/useNumberReachMaxPerTx";
import { useJackpotStore } from "../store/jackpot";
import { useNumberStore } from "../store/number-store";
import { ConfirmSwipeDigitModal } from "./ConfirmSwipeDigitModal";

type NumPadProps = {
  digitArray: any[];
  numbers: (number | string | undefined)[];
  handleInputs: any;
  numberInput: any;
  numberDelete: any;
  isSwipe: boolean;
  onAddNumberClick: () => void;
  onSwiped: () => void;
  gold?: boolean;
};

export function NumPad({
  digitArray,
  numbers,
  handleInputs,
  numberInput,
  numberDelete,
  isSwipe,
  onAddNumberClick,
  onSwiped,
  gold,
}: NumPadProps) {
  const [modalIsOpen, setIsOpen] = useState("");
  const [swipeAtIndex, setSwipeAtIndex] = useState(0);

  const numberStore = useNumberStore();
  const roundNumbers = useJackpotStore((state) => state.roundBuyers);
  const maxNumberPerTx = config.maxNumberPerTx("jackpot");
  const reachMaxPerTx = useNumberReachMaxPerTx(maxNumberPerTx);

  const isNumberOut = useMemo(() => {
    if (numbers.includes(undefined)) {
      return false;
    }
    const numberInt = Number(numbers.join(""));
    const hasRoundNumber = roundNumbers?.some((number) => number.number.includes(numberInt));
    if (hasRoundNumber) return true;

    const hasBuyNumber = numberStore.numbers?.some((number) => number.number === numberInt);
    return hasBuyNumber;
  }, [numbers, roundNumbers, numberStore]);

  const isAddDisabled = useMemo(() => {
    return numbers.includes(undefined) || isNumberOut;
  }, [numbers, isNumberOut]);

  function openConfirmSwipeDigitModal() {
    setIsOpen("is-active");
  }

  function closeModal() {
    setIsOpen("");
  }

  const inputs = useMemo(() => {
    return digitArray.map((_, index) => (
      <div className="relative" key={index}>
        {isSwipe ? (
          <button
            className={`btn-swipe rounded-xl w-12 h-14 lg:mx-2 mx-1 absolute hover:bg-gradient-gold group`}
            style={{ height: "-webkit-fill-available" }}
            onClick={() => {
              setSwipeAtIndex(index);
              openConfirmSwipeDigitModal();
            }}
          >
            <img
              className="w-5 mx-auto -mt-8 group-hover:invisible"
              src={require("../assets/icon/swipe-circle.png")}
              alt=""
            />
            <div className="flex flex-row items-center justify-between invisible p-1 mt-3 group-hover:visible">
              <img className="h-2" src={require("../assets/icon/swipe.png")} alt="Swipe icon" />
              <span className="text-xs font-bold text-black">Swipe</span>
            </div>
          </button>
        ) : (
          <></>
        )}
        <input
          key={index}
          className={`rounded-xl text-black text-4xl font-bold text-center w-12 h-14 lg:mx-2 mx-1`}
          placeholder="-"
          type="text"
          maxLength={1}
          value={numbers[index] ?? ""}
          onChange={(e) => {
            handleInputs(e, index);
          }}
        />
      </div>
    ));
  }, [digitArray, numbers, isSwipe, handleInputs]);

  let numpads = [];
  for (let i = 1; i < 10; i++) {
    numpads.push(
      <button key={i} onClick={() => numberInput(i)}>
        {gold ? (
          <NumberCircleCard
            gold
            number={i}
            className="text-4xl w-[75px] aspect-square"
            borderWidth={1}
          />
        ) : (
          <NumberCircleCard
            number={i}
            className="text-4xl w-[75px] aspect-square"
            borderWidth={1}
          />
        )}
      </button>
    );
  }

  return (
    <div
      className={clsx(
        "mx-auto lg:mx-0",
        gold
          ? "card-border-gradient-gold-bg-black border-sm"
          : "card-border-gradient-blue-to-yellow-bg-black-to-blue"
      )}
    >
      <div
        className={clsx(
          "p-2 rounded-t-xl md:p-5",
          gold ? "bg-gradient-gold-max" : "bg-gradient-blue"
        )}
      >
        <div className={`flex flex-row justify-center`}>{inputs}</div>
      </div>

      <div className="relative grid grid-cols-3 gap-4 px-10 pt-6 pb-4">
        <img
          className="absolute top-0 left-0 z-0 rounded-tl-3xl"
          src={require("../assets/cube-trans2.png")}
          alt=""
        />
        {numpads}
        <button className="col-start-2" onClick={() => numberInput(0)}>
          {gold ? (
            <NumberCircleCard
              gold
              number={0}
              className="text-4xl w-[75px] aspect-square"
              borderWidth={1}
            />
          ) : (
            <NumberCircleCard
              number={0}
              className="text-4xl w-[75px] aspect-square"
              borderWidth={1}
            />
          )}
        </button>
        <button onClick={() => numberDelete()}>
          <img className="w-[75px]" src={require("../assets/portion-box-room/delete.png")} alt="" />
        </button>
      </div>

      <div className="p-5 text-center">
        <Button
          className="w-[200px] lg:w-[320px] uppercase mb-5"
          preset={gold ? "goldMax" : "gold"}
          buttonSize="md"
          disabled={isAddDisabled || reachMaxPerTx}
          onClick={onAddNumberClick}
        >
          {isNumberOut
            ? "Number Reach Limit"
            : reachMaxPerTx
            ? `MAX ${config.maxNumberPerTx("jackpot")} NUMBERS PER TX`
            : "Add"}
        </Button>
      </div>

      <ConfirmSwipeDigitModal
        swipeAtIndex={swipeAtIndex}
        isOpen={modalIsOpen}
        closeModal={closeModal}
        onSwiped={onSwiped}
        gold
      />
    </div>
  );
}
