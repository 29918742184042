import { Asset } from "../../../../config";
import { useChainlinkPrice } from "../../../../hooks/contracts";

const assetCeil = {
  matic: 100,
  btc: 100,
  eth: 100,
  bnb: 1000000,
};

export const useTokenTotalAmount = (
  totalAmount: number,
  convertToAsset?: boolean,
  asset: Asset = "matic"
) => {
  const { latest } = useChainlinkPrice(asset, { enabled: !!convertToAsset, watch: true });

  if (!convertToAsset) {
    return totalAmount;
  } else {
    if (!latest) return 0;
    return Math.ceil((totalAmount / latest.parsedAnswer) * assetCeil[asset]) / assetCeil[asset];
  }
};
