import clsx from "clsx";
import { motion } from "framer-motion";
import { useMemo } from "react";
import { fadeInVariants } from "../animation";
import { useAssetPriceStore } from "../store/asset-price";
import { getDecimal } from "../utils/decimal";
import { JackpotPriceNumbers } from "./JackpotPriceNumbers";

type JackpotPriceCardProps = {
  className?: string;
};

export function JackpotPriceCard({ className }: JackpotPriceCardProps) {
  const price = useAssetPriceStore((s) => s.price);
  const firstBtc = getDecimal(0, price.btc);
  const secondBtc = getDecimal(1, price.btc);
  const firstEth = getDecimal(0, price.eth);
  const secondEth = getDecimal(1, price.eth);
  const firstBnb = getDecimal(0, price.bnb);
  const combined = useMemo(
    () => Number(`${firstBnb}${firstEth}${secondEth}${firstBtc}${secondBtc}`),
    [firstBnb, firstBtc, firstEth, secondBtc, secondEth]
  );

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      className={clsx(
        "py-4 px-3 md:px-12 flex lg:flex-row gap-y-4 flex-col lg:items-center items-start lg:w-max mx-auto gap-x-12",
        className,
        "card-border-gradient-gold-bg-black border-sm"
      )}
    >
      <motion.div className="flex flex-col w-full">
        <div className="text-2xl font-semibold text-center lg:text-xl lg:text-left lg:w-12 font-audiowide">
          Current Number
        </div>
      </motion.div>

      <motion.div variants={fadeInVariants} className="flex flex-row w-full my-3">
        <JackpotPriceNumbers
          icon
          countAnimate
          number={combined}
          className="w-1/5 text-2xl sm:text-3xl md:text-4xl max-w-[70px] lg:w-20 lg:text-5xl"
          containerClassName="w-full justify-center"
        />
      </motion.div>
    </motion.div>
  );
}
