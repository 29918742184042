import { BigNumber, utils } from "ethers";
import { useMemo } from "react";
import { useContractRead } from "wagmi";
import { address, config } from "../../config";
import UniswapV2Router from "../../contracts/UniswapV2Router.json";

const paths = {
  usdt: [],
  // Alternate Token
  busd: [address.BUSD_ADDRESS, address.USDT_ADDRESS],
  usdc: [address.USDC_ADDRESS, address.USDT_ADDRESS],
  dai: [address.DAI_ADDRESS, address.USDT_ADDRESS],
  // WMATIC
  matic: [address.WETH_ADDRESS, address.USDT_ADDRESS],
  bnb: [address.WETH_ADDRESS, address.USDT_ADDRESS],
  dbusd: [],
  bbox: []
};

export type TokenPathKey = keyof typeof paths;

type TicketPriceParams = {
  token: TokenPathKey;
  amount: string | number;
  ticketPrice: number;
};

const SLIPPAGE_BPS = 100;

export function useTicketPrice({ token, amount, ticketPrice }: TicketPriceParams) {
  const path = useMemo(() => paths[token] ?? [], [token]);
  const totalPrice = utils.parseUnits(ticketPrice.toString(), 6).mul(amount);

  const contractRead = useContractRead({
    addressOrName: address.ROUTER_ADDRESS,
    contractInterface: UniswapV2Router,
    functionName: "getAmountsIn",
    cacheTime: config.shortCacheTime,
    staleTime: config.shortCacheTime,
    args: [totalPrice, path],
    enabled: Number(amount) > 0 && path.length > 0,
  });

  const price = useMemo(() => {
    if (path.length === 0) {
      return totalPrice;
    }

    const amounts = contractRead.data;
    const amount = Array.isArray(amounts) ? (amounts[0] as BigNumber) : BigNumber.from(0);
    const slippage = amount.mul(SLIPPAGE_BPS).div(10000);

    return amount.add(slippage);
  }, [contractRead.data, path, totalPrice]);

  return {
    price: path.length > 0 ? price : totalPrice,
    ...contractRead,
  };
}
