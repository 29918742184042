import { useVirtualizer } from "@tanstack/react-virtual";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useRef } from "react";
import { useAccount } from "wagmi";
import { JackpotPriceNumbers } from "../common/JackpotPriceNumbers";
import { usePlayerNumbers } from "../hooks/contracts/jackpot";

export function TableMyTicket() {
  const { address: account } = useAccount();
  const { data: plays, setSize, size, isValidating, isReachEnded } = usePlayerNumbers(account);
  const parentRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: plays.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 80,
    overscan: 20,
  });

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();
    const lastItemIndex = lastItem?.index;
    if (!lastItemIndex || isValidating || isReachEnded) return;

    if (lastItemIndex >= plays.length - 1) {
      setSize(size + 1);
    }
  }, [rowVirtualizer.getVirtualItems(), isValidating, size, plays, isReachEnded]);

  return (
    <div className="pt-5">
      {plays.length > 0 ? (
        <>
          <div className="grid grid-cols-12 px-2 py-4 text-xs font-light md:px-4 text-light-gray lg:text-base">
            <div className="col-span-7 md:col-span-4">Ticket Number</div>
            <div className="hidden col-span-3 pl-3 md:block lg:pl-3">Round</div>
            <div className="col-span-3 pl-0 lg:pl-12 md:pl-9">Result</div>
            <div className="col-span-2">Prize</div>
          </div>
          <div className="h-[440px] overflow-y-auto scrollbar-gray pb-2" ref={parentRef}>
            <div
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: "100%",
                position: "relative",
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualItem) => (
                <div
                  key={virtualItem.key}
                  className="absolute top-0 left-0 w-full"
                  style={{
                    height: `${virtualItem.size}px`,
                    transform: `translateY(${virtualItem.start}px)`,
                  }}
                >
                  <div className="grid items-center grid-cols-12 px-2 py-3 mb-2 text-sm font-semibold rounded-2xl md:px-4 md:py-4 bg-blue-gray lg:text-2xl md:text-xl sm:text-sm h-[70px]">
                    <div className="col-span-7 pr-4 md:pr-0 md:col-span-4">
                      <JackpotPriceNumbers
                        number={plays[virtualItem.index].number}
                        highlightCount={plays[virtualItem.index].highlightCount}
                        className="w-8 text-sm md:w-10 lg:text-2xl md:text-xl"
                      />
                    </div>
                    <div className="hidden pl-3 md:block md:col-span-3">
                      <div className="md:text-sm text-[10px] lg:text-sm font-medium">
                        #{plays[virtualItem.index].round}
                      </div>
                      <div className="md:text-xs text-[8px] font-light lg:text-sm">
                        {dayjs(plays[virtualItem.index].transactionAt).format(
                          "MMM DD, YYYY, HH:mm"
                        )}
                      </div>
                    </div>
                    <div className="col-span-3 pl-0 font-bold md:col-span-3 md:pl-9 lg:pl-12">
                      <div
                        className={clsx(
                          plays[virtualItem.index].status === "LOSE"
                            ? "text-brownish-grey"
                            : "text-gradient gold"
                        )}
                      >
                        {plays[virtualItem.index].status}
                      </div>
                      {plays[virtualItem.index].winGame && (
                        <div className="text-gradient gold flex gap-x-1 text-[8px] -mt-1 md:text-xs font-bold">
                          {plays[virtualItem.index].winGame}
                        </div>
                      )}
                      <div className="text-[10px] md:hidden">
                        Round #{plays[virtualItem.index].round}
                      </div>
                    </div>
                    <div className="col-span-2 text-lg font-bold text-gradient gold">
                      {plays[virtualItem.index].winGame
                        ? `+$${plays[virtualItem.index].prize?.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : "-"}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="m-auto flex flex-col items-center justify-center w-max h-[405px]">
          <img className="my-3 lg:w-16 w-14" src={require("../assets/bb-icon1.png")} alt="" />
          <div className="font-normal lg:text-lg text-base text-[#a5a5a5] text-center">
            <p>Let's play!.</p>
          </div>
        </div>
      )}
    </div>
  );
}
