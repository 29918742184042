import { BigNumberish, ContractFactory } from "ethers";
import { useState } from "react";
import { useContractWrite } from "wagmi";
import { address } from "../../../config";
import Jackpot from "../../../contracts/Jackpot.json";
import { useJackpotTicket } from "../../../context/JackpotTicketContext";

type WriteFns = "play" | "playNativeToken" | "claim" | "claimBySpecificInfo";

// prettier-ignore
type WriteArgs<Fn> =
  Fn extends "play" ? [numbers: BigNumberish[], roundId?: BigNumberish, token?: string] :
  Fn extends "playNativeToken" ? [amount: BigNumberish, numbers: BigNumberish[], roundId?: BigNumberish] :
  Fn extends "claim" ? [BigNumberish[]] :
  Fn extends "claimBySpecificInfo" ? [roundIds: BigNumberish[], playInfoIndexes: number[], gameAddresses: string[]] :
  never;

export type WriteError =
  | "InvalidFeeBPS"
  | "NotWhitelistGame"
  | "PlayTokenInvalid"
  | "InvalidNumberAndSizeLength"
  | "AlreadyStarted"
  | "PlayBeforeGenesisRound"
  | "ExecuteRoundTooEarly"
  | "RoundClosed"
  | "RoundNotOpen"
  | "NumberLimitExceeded"
  | "ExceedLimitNumberPerTx"
  | "PriceFeedInvalid"
  | "InsufficientAmount";

export function useJackpotWrite<WriteFn extends WriteFns>(
  functionName: WriteFn,
  args?: WriteArgs<WriteFn>,
  onError?: (error: WriteError) => void
) {
  const jackpotTicket = useJackpotTicket();
  const [writeError, setWriteError] = useState<WriteError>();
  const overrides: any = {
    value: functionName === "playNativeToken" ? args?.[0] : "0",
  };

  return {
    writeError,
    ...useContractWrite({
      addressOrName: jackpotTicket.contractAddress,
      contractInterface: Jackpot,
      functionName,
      mode: "recklesslyUnprepared",
      args: functionName === "playNativeToken" ? args?.slice(1) : args,
      overrides,
      onError(error: any) {
        const _writeError = ContractFactory.getInterface(Jackpot).parseError(error.error.data.data)
          .name as WriteError;
        setWriteError(_writeError);
        onError?.(_writeError);
      },
    }),
  };
}
