import { motion } from "framer-motion";
import { AnimatedText } from "../common/AnimatedText";
import { config } from "../config";
import { useJackpotTicket } from "../context/JackpotTicketContext";
import { useJackpotPrize } from "../hooks/contracts/jackpot/useJackpotPrize";

export function WinningCriteria() {
  const jackpotTicket = useJackpotTicket();
  const jackpotPrize = useJackpotPrize();

  return (
    <div className="relative bg-gradient-black-to-blue">
      <img
        alt=""
        className="absolute bottom-0 right-0 hidden w-96 -z-0 lg:block"
        src={require("../assets/cube-trans6.png")}
      />
      <div className="container py-12 mx-auto">
        <motion.div
          className="text-center"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "0px 0px -20% 0px" }}
          transition={{ delayChildren: 0.2, staggerChildren: 0.04 }}
        >
          <AnimatedText size="md" text="W" goldMax />
          <AnimatedText size="md" text="I" goldMax />
          <AnimatedText size="md" text="N" goldMax />
          <AnimatedText size="md" text="N" goldMax />
          <AnimatedText size="md" text="I" goldMax />
          <AnimatedText size="md" text="N" goldMax />
          <AnimatedText size="md" text="G" goldMax />
          <br className="lg:hidden" />
          <AnimatedText size="md" text="C" className="lg:ml-4" />
          <AnimatedText size="md" text="R" />
          <AnimatedText size="md" text="I" />
          <AnimatedText size="md" text="T" />
          <AnimatedText size="md" text="E" />
          <AnimatedText size="md" text="R" />
          <AnimatedText size="md" text="I" />
          <AnimatedText size="md" text="A" />
        </motion.div>
        <p className="text-lg font-light text-center">
          Decentralized Jackpot Game - Guess nuumber to Win the Prize!
        </p>

        <div className="grid grid-cols-1 gap-10 pt-10 mt-6 lg:grid-cols-2">
          <img alt="" className="z-10 mx-auto -mt-20" src={require("../assets/graph6.png")} />
          <div className="-mt-20 lg:-mt-0">
            <div className="flex items-center">
              <img alt="" className="w-10" src={require("../assets/decimal-box/icon.png")} />
              <p className="ml-3 text-2xl font-bold">The Jackpot</p>
            </div>
            <p className="mt-6 text-lg font-light">
              Jackpot is lottery on chain 5 digits that offers a guaranteed jackpot’s prize pool of{" "}
              {jackpotPrize.toLocaleString("en-US")} {jackpotTicket.currentTicket.toUpperCase()}.
              Ticket {config.jackpotPrice(jackpotTicket.currentTicket).toLocaleString("en-US")}{" "}
              {jackpotTicket.currentTicket.toUpperCase()} The results come from decimals of
              BNB/USDT, ETH/USDT, and BTC/USDT from Binance combined together (The first decimals
              from BNB, the next two from ETH, and the last two from BTC).
            </p>
            <div className="text-center">
              <a
                href="https://info.billionbox.io/products/lottery-on-chain-jackpot-game"
                target="_blank"
                className="relative z-10 inline-block mt-12 font-bold uppercase border-b border-bnb text-gradient gold hover:opacity-70"
                rel="noopener noreferrer"
              >
                Read more
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 lg:h-[150px] h-[100px] bg-gradient-to-t from-black z-0"></div>
    </div>
  );
}
