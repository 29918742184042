import dayjs from "dayjs";
import { useEffect, useMemo } from "react";
import { LinkButton } from "../common";
import { config } from "../config";
import { useJackpotTicket } from "../context/JackpotTicketContext";
import { useCountdown } from "../hooks";
import { useCurrentRound, useRoundNumberLength } from "../hooks/contracts/jackpot";
import { useJackpotPrize } from "../hooks/contracts/jackpot/useJackpotPrize";
import { useTicketIcon } from "../hooks/contracts/jackpot/useTicketIcon";
import { useJackpotStore } from "../store/jackpot";
import { bigNumberToTime } from "../utils/formatter";
import { AnimateCalculating } from "./AnimateCalculating";
import { MyReward } from "./MyReward";

export function Dashboard() {
  const jackpotTicket = useJackpotTicket();
  const round = useCurrentRound();
  const roundNumberLength = useRoundNumberLength(round?.roundId);
  const jackpotStore = useJackpotStore();

  const [, , countdown, countdownNumber] = useCountdown(
    bigNumberToTime(round?.executeAfterTime),
    true
  );

  const ticketIcon = useTicketIcon();
  const jackpotPrize = useJackpotPrize();

  const [, , , closeCountdownNumber] = useCountdown(bigNumberToTime(round?.closeTime), true);

  const executedAt = useMemo(
    () => dayjs(bigNumberToTime(round?.executeAfterTime.sub(60))).format("MMM DD, HH:mm"),
    [round?.executeAfterTime]
  );

  useEffect(() => {
    if (countdownNumber <= 0 && !jackpotStore.isCalculating) {
      jackpotStore.setIsCalculating(true);
    } else if (countdownNumber > 0 && jackpotStore.isCalculating) {
      jackpotStore.setIsCalculating(false);
    }
  }, [countdownNumber, jackpotStore]);

  useEffect(() => {
    if (closeCountdownNumber <= 0 && !jackpotStore.isClosed) {
      jackpotStore.setIsClosed(true);
    } else if (closeCountdownNumber > 0 && jackpotStore.isClosed) {
      jackpotStore.setIsClosed(false);
    }
  }, [closeCountdownNumber, jackpotStore]);

  return (
    <div className="relative bg-cover bg-jackpot-dashboard">
      <div className="w-full h-16 bg-gradient-to-b from-black"></div>
      <div className="container relative z-10 pb-10 mx-auto">
        <div className="grid grid-cols-2 gap-5 lg:grid-cols-12">
          <div className="relative flex flex-col items-center col-span-2 py-4 overflow-hidden lg:ml-20 bg-gradient-gold-max rounded-xl lg:col-span-7">
            <div className="flex flex-row items-center gap-x-2">
              <img className="w-3" src={require("../assets/icon/trophy.png")} alt="" />
              <span className="text-base text-white">Jackpot</span>
            </div>
            <div className="flex items-center mt-1 text-3xl font-bold text-white font-roboto-condensed gap-x-2">
              <img src={ticketIcon} alt="" className="w-8" />
              {jackpotPrize.toLocaleString("en-US")} !!
            </div>
            <div className="text-xs text-white">1 Round per day</div>
            <a
              href={config.explorerUrl + "/address/" + jackpotTicket.contractAddress}
              className="mt-1 text-[9px] uppercase text-white/60 border-b border-dashed border-white/40 hover:text-white hover:border-white"
              rel="noreferrer noopener"
              target="_blank"
            >
              View Contract
            </a>
            <img
              alt=""
              className="absolute bottom-0 left-[-20px] h-[110%] rounded-bl-2xl"
              src={require("../assets/cube-trans7.png")}
            />
          </div>
          <div className="lg:col-span-5 lg:mr-20">
            <div className="flex flex-col items-center justify-center py-4 gap-y-2 card-border-gradient-gold-bg-black border-sm">
              <div className="flex items-center mb-0 text-base gap-x-2">
                <img className="w-4" alt="countdown" src={require("../assets/icon/clock.png")} />
                <span>Draw in</span>
              </div>
              <div className="text-xl lg:text-3xl font-roboto-condensed">
                {countdownNumber <= 0 ? (
                  <AnimateCalculating />
                ) : (
                  <span className="font-bold text-gradient gold">{countdown}</span>
                )}
              </div>
              <div className="flex flex-col items-center lg:flex-row gap-x-2 text-[10px]">
                <span className="text-white/70">At {executedAt}</span>
                <span className="text-white/40">Every 24 hours / round</span>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center py-6 card-border-gradient-gold-bg-black border-sm lg:col-span-4">
            <div className="mb-1 text-base lg:text-xl md:text-lg lg:mb-3">
              Tickets in this Round
            </div>
            <div className="text-4xl text-gradient gold font-roboto-condensed">
              {roundNumberLength?.toLocaleString() ?? 0}
            </div>
          </div>
          <div className="relative flex flex-col items-center py-6 overflow-hidden card-border-gradient-gold-bg-black border-sm lg:col-span-4">
            <p className="mb-1 text-base lg:text-xl md:text-lg lg:mb-3">My Wallet</p>
            <LinkButton className="z-10 px-12 py-1" preset="goldMax" to="/my-dashboard">
              VIEW
            </LinkButton>
            <img
              className="absolute left-0 right-0"
              alt="ticket"
              src={require("../assets/tickets.png")}
            />
          </div>
          <MyReward />
        </div>
      </div>
      <div className="absolute bottom-0 w-full h-36 bg-gradient-to-t from-black"></div>
    </div>
  );
}
