import { useEffect, useState } from "react";
import { TopTabgroup } from "../../common/TopTabGroup";
import { useMultiplierBoxRanking } from "../../hooks/contracts/multiplier-box";
import { useLatestBuyers } from "../../hooks/contracts/multiplier-box/useLatestBuyers";
import { useMaxPlayinfoTabStore } from "../../store/max-playinfo-tab-store";
import { useCurrentRoundState } from "../play/CurrentRoundProvider";
import { TabGroupLive } from "./TabGroupLive";
import { TableLive } from "./TableLive";
import { TableMyPlay } from "./TableMyPlay";
import { TableWiningRank } from "./TableWiningRank";

const tabs = ["1 DIGIT", "2 DIGITS"];

export const LivePlay = () => {
  const { gameAddress = "", setDigits } = useCurrentRoundState();
  const { data: ranks } = useMultiplierBoxRanking(gameAddress);
  const { activeTab, setActiveTab } = useMaxPlayinfoTabStore();
  const [activeDigitIndex, setActiveDigitIndex] = useState(0);
  const buyers = useLatestBuyers(gameAddress);

  useEffect(() => {
    setDigits?.(activeDigitIndex + 1);
  }, [activeDigitIndex]);

  return (
    <div id="play" className="mt-5 bg-center bg-cover bg-live-play">
      <div className="w-full h-[80px] bg-gradient-to-b from-black"></div>
      <div className="container">
        <TopTabgroup tabs={tabs} activeTab={activeDigitIndex} onTabChange={setActiveDigitIndex} />
        <div className="pt-4 items-center justify-between md:px-10 px-3 overflow-hidden bg-black-border-gold lg:h-[600px] h-[480px] z-20 -mt-4">
          <TabGroupLive
            color="gold"
            className="w-full mx-auto mt-6 lg:w-[470px]"
            activeTabIndex={activeTab}
            onTabChange={setActiveTab}
          />
          {activeTab === 0 && <TableLive buyers={buyers} />}
          {activeTab === 1 && <TableWiningRank ranks={ranks?.ranking ?? []} />}
          {activeTab === 2 && <TableMyPlay />}
        </div>
      </div>
    </div>
  );
};
