import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import App from "../App";
import { Home } from "../home";
import { DecimalJackpot } from "./DecimalJackpot";
import { MultiplierBox } from "./MultiplierBox";
import { MyDashboard } from "./MyDashboard";
import { PortionBox } from "./PortionBox";
import { PortionBoxRoom } from "./PortionBoxRoom";
import { Prediction } from "./Prediction";
import { PredictionPro } from "./PredictionPro";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export function AppRoutes() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route element={<App />}>
          <Route path="/" element={<Home />} />
          <Route path="/prediction" element={<Prediction />} />
          <Route path="/bb-max" element={<MultiplierBox />} />
          <Route path="/prediction-pro/high-low" element={<PredictionPro game="high-low" />} />
          <Route path="/prediction-pro/even-odd" element={<PredictionPro game="even-odd" />} />
          <Route path="/decimal-box" element={<PortionBox />} />
          <Route path="/decimal-box/:room" element={<PortionBoxRoom />} />
          <Route path="/jackpot" element={<DecimalJackpot />} />
          {/* <Route path="/multiplier-box" element={<MultiplierBox />} /> */}
          <Route path="/my-dashboard" element={<MyDashboard />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
