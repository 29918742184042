import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
  useAnswerPriceToAssetPrices,
  useCurrentRound,
  useRound,
  useRoundNumberLength,
  useWinnersInRound,
} from "../hooks/contracts/jackpot";
import { useTicketIcon } from "../hooks/contracts/jackpot/useTicketIcon";
import { FinishedRoundWinningNumber } from "./FinishedRoundWinningNumber";
import { WinnerTable } from "./WinnerTable";

export function FinishedRound() {
  const currentRound = useCurrentRound();
  const [showHistoryIndex, setShowHistoryIndex] = useState<number>();
  const [roundInputId, setRoundInputId] = useState<string>();
  const [roundId, setRoundId] = useState<number>();
  const round = useRound(roundId);
  const roundAnswer = useAnswerPriceToAssetPrices(round?.answerPrice);
  const { winners } = useWinnersInRound(round?.roundId, round?.answer);
  const roundNumberLength = useRoundNumberLength(round?.roundId);

  const toggleHistoryDetail = useCallback(
    (index: number) => {
      setShowHistoryIndex(showHistoryIndex === index ? undefined : index);
    },
    [showHistoryIndex]
  );

  const lastFinishedRound = useMemo(
    () => currentRound?.roundId.sub(1).toNumber() ?? 1,
    [currentRound]
  );

  const ticketIcon = useTicketIcon();

  useEffect(() => {
    if (!roundId) {
      setRoundId(lastFinishedRound);
      setRoundInputId(String(lastFinishedRound));
    }
  }, [lastFinishedRound, roundId]);

  const changeRound = useCallback(
    (change: number) => {
      const showRoundId = Number(roundId);
      const newRoundId = change + showRoundId;

      if (newRoundId > 0 && newRoundId <= lastFinishedRound) {
        setRoundId(newRoundId);
        setRoundInputId(String(newRoundId));
      }
    },
    [lastFinishedRound, roundId]
  );

  return (
    <div id="finished-round">
      <div className="py-3 mt-6 mb-4 rounded-lg bg-gradient-gold-max">
        <div className="flex flex-row items-center justify-between px-3 md:px-5">
          <div className="flex items-center">
            <p className="pr-3 text-sm sm:text-xl md:text-xl">Round</p>
            <div className="flex items-center">
              <input
                type="number"
                className="rounded-xl bg-white font-bold md:text-3xl sm:text-3xl text-xl text-black py-1 md:w-[90px] w-[80px] px-2 text-center"
                value={roundInputId}
                onChange={(e) => {
                  const { value } = e.target;
                  setRoundInputId(value);

                  const numberValue = Number(value);
                  if (numberValue > 0 && numberValue <= lastFinishedRound) {
                    setRoundId(numberValue);
                  }
                }}
              />
              <div className="flex pl-2 md:pl-3 text-[9px] sm:text-sm font-light pt-2 md:pt-0 justify-center text-white/90">
                <div>
                  {round ? (
                    <div className="space-x-1">
                      <span>
                        {dayjs(round!.executeAfterTime.mul(1000).toNumber())
                          .subtract(1, "minute")
                          .format("MMM DD, YYYY")}
                      </span>
                      <span className="font-bold">
                        {dayjs(round!.executeAfterTime.mul(1000).toNumber())
                          .subtract(1, "minute")
                          .format("HH:mm")}
                      </span>
                    </div>
                  ) : (
                    <Skeleton containerClassName="ml-2 inline-block w-24" />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex mt-1 md:mt-0">
            <button onClick={() => changeRound(-1)}>
              <img
                className="w-6 lg:w-8 sm:w-8 md:w-9"
                src={require("../assets/btn-l.png")}
                alt=""
              />
            </button>
            <button onClick={() => changeRound(1)}>
              <img
                className="w-6 ml-2 md:w-9 sm:w-8 lg:w-8"
                src={require("../assets/btn-r.png")}
                alt=""
              />
            </button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-5">
        <div className="flex justify-between md:px-2 font-roboto-condensed">
          <div className="flex items-center text-[13px] gap-x-1 md:gap-x-2 md:text-base">
            <img className="w-4 md:w-5" src={require("../assets/icon/bnb.png")} alt="" />
            <span>BNB</span>
            <span className="font-bold">
              ${roundAnswer.bnb}.{" "}
              <span className="py-[1px] px-1 md:px-[6px] rounded-lg border border-bnb">
                {roundAnswer.bnbDecimal.toString().padStart(1, "0")}
              </span>
            </span>
          </div>
          <div className="flex items-center text-[13px] gap-x-1 md:gap-x-2 md:text-base">
            <img className="w-4 md:w-5" src={require("../assets/icon/eth.png")} alt="" />
            <span>ETH</span>
            <span className="font-bold">
              ${roundAnswer.eth}.{" "}
              <span className="py-[1px] px-1 md:px-[6px] rounded-lg border border-eth">
                {roundAnswer.ethDecimal.toString().padStart(2, "0")}
              </span>
            </span>
          </div>
          <div className="flex items-center text-[13px] gap-x-1 md:gap-x-2 md:text-base">
            <img className="w-4 md:w-5" src={require("../assets/icon/btc.png")} alt="" />
            <span>BTC</span>
            <span className="font-bold">
              ${roundAnswer.btc}.{" "}
              <span className="py-[1px] px-1 md:px-[6px] rounded-lg border border-btc">
                {roundAnswer.btcDecimal.toString().padStart(2, "0")}
              </span>
            </span>
          </div>
        </div>
        <FinishedRoundWinningNumber
          number={round?.answer.toString().padStart(5, "0") ?? "-----"}
          totalPlayers={roundNumberLength ?? 0}
        />

        <div className="flex flex-col h-[330px] overflow-y-auto no-scrollbar pb-12">
          {winners.length === 0 ? (
            <div className="flex items-center justify-center h-full mb-12 -mt-2 text-lg text-gray-400">
              Didn’t have a winner in this round.
            </div>
          ) : (
            <>
              {winners.map((winner, index) => (
                <div
                  key={index}
                  className="mb-2 border border-[#353734] bg-[#04132c] rounded-2xl font-roboto-condensed cursor-pointer"
                  onClick={() => toggleHistoryDetail(index)}
                >
                  <div className="items-center rounded-2xl grid grid-cols-12 px-4 py-3 bg-[#04132c] gap-3 md:text-base text-sm">
                    <div className="col-span-4 font-bold text-gradient gold">{winner.match}</div>
                    <div className="flex items-center col-span-4 text-sm font-bold md:text-xl gap-x-2">
                      <img className="w-5" alt="ticket icon" src={ticketIcon} />
                      {winner.prize.toLocaleString("en-US")}
                    </div>
                    <div className="col-span-3 text-right">{winner.players.length} Winners</div>

                    <div className="col-span-1">
                      <img
                        alt=""
                        className="w-4"
                        src={
                          showHistoryIndex !== index
                            ? require("../assets/btn-down.png")
                            : require("../assets/btn-up.png")
                        }
                      />
                    </div>
                  </div>
                  <div className="overflow-hidden">
                    <AnimatePresence>
                      {showHistoryIndex === index && (
                        <motion.div
                          className="flex flex-col items-center pb-3"
                          initial={{ y: "-40%", opacity: 0 }}
                          exit={{ y: "-40%", opacity: 0 }}
                          animate={{ y: "0%", opacity: 1 }}
                          transition={{ duration: 0.4 }}
                        >
                          <WinnerTable
                            players={winner.players}
                            highlightCount={winner.highlightCount}
                            matchType={winner.match}
                            prize={winner.prize}
                          />
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
