import clsx from "clsx";
import TimeIcon from "../assets/icon/time.png";
import { Loading, useNotificationContext } from "../common";
import { config } from "../config";
import { useJackpotStore } from "../store/jackpot";
import { useNumberStore } from "../store/number-store";
import { useNumberReachMaxPerTx } from "../hooks";

export function Random() {
  const random = [1, 10, 50, 100];
  const numberStore = useNumberStore();
  const jackpotStore = useJackpotStore();
  const { pushNotification } = useNotificationContext();
  const maxNumberPerTx = config.maxNumberPerTx("jackpot");
  const reachMaxPerTx = useNumberReachMaxPerTx(maxNumberPerTx);

  function getRandomNumbers(amount: number, excludeNumbers: number[]) {
    let randomAmountLeft = amount;

    const numbersMap = excludeNumbers.reduce(
      (prev, cur) => ({ ...prev, [cur]: true }),
      {} as Record<number, boolean>
    );

    const addedNumbers: number[] = [];

    while (randomAmountLeft > 0) {
      let randomNumber = Math.floor(Math.random() * 99999);

      while (numbersMap[randomNumber]) {
        randomNumber = Math.floor(Math.random() * 99999);
      }

      randomAmountLeft--;
      numbersMap[randomNumber] = true;
      addedNumbers.push(randomNumber);
    }

    return addedNumbers;
  }

  async function addRandomNumbers(amount: number) {
    const numbers = numberStore.numbers.map((number) => number.number);
    const purchasedNumbers = useJackpotStore.getState().getRoundNumbers();
    const addedNumbers = getRandomNumbers(amount, purchasedNumbers.concat(numbers));

    numberStore.addNumbers(addedNumbers, config.maxNumberPerTx("jackpot"));

    pushNotification({
      type: "random-many",
      number: amount.toString(),
      key: Math.random().toString(),
    });
  }

  return (
    <div
      className={clsx(
        "relative flex flex-col items-center px-4 pt-3 pb-2 overflow-hidden text-center lg:pt-5 card-border-gradient-gold-bg-black",
        jackpotStore.isClosed ? "!border-0" : "border-sm"
      )}
    >
      {jackpotStore.isCalculating ? (
        <Loading text="Calculating.." />
      ) : (
        jackpotStore.isClosed && <Loading icon={TimeIcon} text="Waiting draw.." />
      )}
      <img className="lg:h-[35px] h-[45px]" src={require("../assets/random.png")} alt="" />
      <p className="mt-1 text-lg font-bold font-roboto-condensed">RANDOM NUMBER</p>
      <p className="text-xs font-light">No idea? try random number</p>
      <div className="relative grid grid-cols-2 gap-1 mt-4 lg:grid-cols-4">
        {random.map((number) => (
          <button
            key={number}
            className="lg:px-1 px-4 py-1 border border-white/25 rounded-xl hover:bg-gradient-gold-max hover:text-white text-[#a7a1a1]"
            onClick={() => addRandomNumbers(number)}
          >
            <p className="font-semibold text-white">{number}</p>
            <p className="text-[10px]">Number</p>
          </button>
        ))}
        {reachMaxPerTx && (
          <div className="absolute inset-0 flex items-center justify-center rounded-xl bg-black/90">
            <span className="-mt-1 text-xs font-bold uppercase text-white/90">
              Number React Limit
            </span>
          </div>
        )}
      </div>
      <p className="text-[10px] font-normal text-center text-[#a7a1a1] mt-2">
        Select the total number you want to random
      </p>
    </div>
  );
}
