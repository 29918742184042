import { BigNumberish } from "ethers";
import { useMemo } from "react";
import { address, config } from "../../../config";
import { isNumberWinning } from "../../../decimal-jackpot/utils/gameWinning";
import { useRoundBuyers } from "./useRoundBuyers";
import { useJackpotTicket } from "../../../context/JackpotTicketContext";

type WinnerGroup = {
  match: string;
  prize: number;
  game: string;
  highlightCount: number;
  players: {
    address: string;
    number: number;
  }[];
};

export const useWinnersInRound = (roundId?: BigNumberish, roundAnswer?: BigNumberish) => {
  const { buyers } = useRoundBuyers(roundId);
  const jackpotTicket = useJackpotTicket();

  const basedWinnerGroups = useMemo(
    () => {
      const payout = jackpotTicket.currentTicket === "usdt" ? config.jackpotPayout : config.jackpotBBOXPayout
      return [
      {
        match: "Jackpot",
        players: [],
        game: address.MATCH_5_GAME,
        prize:
          (payout[address.MATCH_5_GAME] *
            config.jackpotPrice(jackpotTicket.currentTicket)) /
          100,
        highlightCount: 5,
      },
      {
        match: "Match First 4",
        players: [],
        game: address.MATCH_4_GAME,
        prize:
          (payout[address.MATCH_4_GAME] *
            config.jackpotPrice(jackpotTicket.currentTicket)) /
          100,
        highlightCount: 4,
      },
      {
        match: "Match First 3",
        players: [],
        game: address.MATCH_3_GAME,
        prize:
          (payout[address.MATCH_3_GAME] *
            config.jackpotPrice(jackpotTicket.currentTicket)) /
          100,
        highlightCount: 3,
      },
      {
        match: "Match First 2",
        players: [],
        game: address.MATCH_2_GAME,
        prize:
          (payout[address.MATCH_2_GAME] *
            config.jackpotPrice(jackpotTicket.currentTicket)) /
          100,
        highlightCount: 2,
      },
      {
        match: "Match First 1",
        players: [],
        game: address.MATCH_1_GAME,
        prize:
          (payout[address.MATCH_1_GAME] *
            config.jackpotPrice(jackpotTicket.currentTicket)) /
          100,
        highlightCount: 1,
      },
      {
        match: "Shuffle 4",
        players: [],
        game: address.SHUFFLE_4_GAME,
        prize:
          (payout[address.SHUFFLE_4_GAME] *
            config.jackpotPrice(jackpotTicket.currentTicket)) /
          100,
        highlightCount: 4,
      },
      {
        match: "Shuffle 3",
        players: [],
        game: address.SHUFFLE_3_GAME,
        prize:
          (payout[address.SHUFFLE_3_GAME] *
            config.jackpotPrice(jackpotTicket.currentTicket)) /
          100,
        highlightCount: 3,
      },
    ]},
    [jackpotTicket]
  );

  const winners = useMemo(() => {
    const winnerGroups: WinnerGroup[] = structuredClone(basedWinnerGroups);

    if (!roundAnswer || !buyers) return winnerGroups;

    for (const buyer of buyers) {
      for (const number of buyer.number) {
        let highestPrize = 0;
        let highestGameIndex = -1;

        for (const [index, winnerGroup] of winnerGroups.entries()) {
          if (isNumberWinning(number, roundAnswer, winnerGroup.game)) {
            const prize = winnerGroup.prize;
            if (prize > highestPrize) {
              highestPrize = prize;
              highestGameIndex = index;
            }
          }
        }

        if (highestGameIndex >= 0) {
          winnerGroups[highestGameIndex].players.push({
            address: buyer.user,
            number: number,
          });
        }
      }
    }

    return winnerGroups;
  }, [buyers, roundAnswer]);

  return { winners, buyers };
};
