import { utils } from "ethers";
import { useMemo } from "react";
import { erc20ABI, useContractRead } from "wagmi";
import { config } from "../../config";

export function useTokenAllowance(token: string, owner?: string, spender?: string) {
  const { data, ...rest } = useContractRead({
    addressOrName: token,
    contractInterface: erc20ABI,
    functionName: "allowance",
    args: [owner, spender],
    enabled: Boolean(owner),
    watch: true,
  });

  const allowance = useMemo(
    () => data && Number(utils.formatUnits(data as any, config.stablecoinDecimals)),
    [data]
  );

  return { allowance, ...rest };
}
