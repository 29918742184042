/// <reference types="@welldone-software/why-did-you-render" />

import "./wdyr";
import "@rainbow-me/rainbowkit/styles.css";
import React from "react";
import ReactDOM from "react-dom/client";
import "react-loading-skeleton/dist/skeleton.css";
import "./assets/css/animations.css";
import "./buffer-patch";
import "./index.scss";

import { WagmiConfigProvider } from "./common/WagmiConfigProvider";
import reportWebVitals from "./reportWebVitals";
import { AppRoutes } from "./routes/AppRoutes";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <WagmiConfigProvider>
      <AppRoutes />
    </WagmiConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
