import { useMemo } from "react";
import { useJackpotTicket } from "../../../context/JackpotTicketContext";
import { address, config } from "../../../config";

export function useJackpotPrize() {
  const jackpotTicket = useJackpotTicket();
  
  const jackpotPrize = useMemo(() => {
    const ticketPrice = config.jackpotPrice(jackpotTicket.currentTicket);
    const payout =
      jackpotTicket.currentTicket === "usdt" ? config.jackpotPayout : config.jackpotBBOXPayout;
    return (ticketPrice * payout[address.MATCH_5_GAME]) / 100;
  }, [jackpotTicket.currentTicket]);

  return jackpotPrize;
}
