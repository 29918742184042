import { NumberAssetCircleCard } from "../common/NumberAssetCircleCard";

type Props = {
  number: string;
  totalPlayers: number;
};

const assetIndex = ["bnb", "eth", "eth", "btc", "btc"] as const;

export function FinishedRoundWinningNumber({ number, totalPlayers }: Props) {
  return (
    <div className="flex flex-col">
      <p className="text-xl font-bold text-center font-roboto-condensed text-gradient gold">
        Winning Number
      </p>
      <div className="flex justify-center my-3">
        {number.split("").map((n, i) => (
          <NumberAssetCircleCard
            className="w-10 text-2xl font-bold border-[2px] mx-[4px]"
            asset={assetIndex[i]}
            number={n}
            key={i}
          />
        ))}
      </div>
      <div className="text-base font-semibold text-center">
        <span className="text-gradient gold"> {totalPlayers.toLocaleString()} </span>
        <span>numbers in this round</span>
      </div>
    </div>
  );
}
