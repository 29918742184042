import { getDefaultWallets } from "@rainbow-me/rainbowkit";
import { configureChains, createClient } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";
import { config } from "../config";

const supportChains = [config.chain];
const providers = [
  publicProvider({ priority: 1 }),
  jsonRpcProvider({
    priority: 1,
    rpc(chain) {
      return {
        http: "https://rpc.ankr.com/bsc",
      };
    },
  }),
  jsonRpcProvider({
    priority: 1,
    rpc(chain) {
      return {
        http: "https://bsc-dataseed1.defibit.io",
      };
    },
  }),
  jsonRpcProvider({
    priority: 1,
    rpc(chain) {
      return {
        http: "https://bsc.publicnode.com",
      };
    },
  }),
];

const { chains, provider } = configureChains(supportChains, providers);
const { connectors } = getDefaultWallets({ appName: "Billionbox", chains });

export const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});
