import { BigNumberish, utils } from "ethers";
import {
  JACKPOT_GAMES_NUMBER_HIGHLIGHT,
  JACKPOT_GAME_TITLES,
  config,
  jackpotGames,
} from "../../config";
import { PlayInfo } from "../../hooks/contracts/jackpot";
import { getAllPermutationNumbersMap } from "../../utils/permutation-numbers";
import { address } from "./../../config";

type WinningGame = {
  address: string;
  title: string;
  highlightCount: number;
};

export const gameWinningLogic = {
  [address.MATCH_1_GAME.toLowerCase()]: (number: BigNumberish, answer: BigNumberish) => {
    return (
      number.toString().padStart(5, "0").slice(0, 1) ===
      answer.toString().padStart(5, "0").slice(0, 1)
    );
  },
  [address.MATCH_2_GAME.toLowerCase()]: (number: BigNumberish, answer: BigNumberish) => {
    return (
      number.toString().padStart(5, "0").slice(0, 2) ===
      answer.toString().padStart(5, "0").slice(0, 2)
    );
  },
  [address.MATCH_3_GAME.toLowerCase()]: (number: BigNumberish, answer: BigNumberish) => {
    return (
      number.toString().padStart(5, "0").slice(0, 3) ===
      answer.toString().padStart(5, "0").slice(0, 3)
    );
  },
  [address.MATCH_4_GAME.toLowerCase()]: (number: BigNumberish, answer: BigNumberish) => {
    return (
      number.toString().padStart(5, "0").slice(0, 4) ===
      answer.toString().padStart(5, "0").slice(0, 4)
    );
  },
  [address.MATCH_5_GAME.toLowerCase()]: (number: BigNumberish, answer: BigNumberish) => {
    return number.toString().padStart(5, "0") === answer.toString().padStart(5, "0");
  },
  [address.SHUFFLE_4_GAME.toLowerCase()]: (number: BigNumberish, answer: BigNumberish) => {
    const permutationNumbers = getAllPermutationNumbersMap(
      answer.toString().padStart(5, "0").slice(0, 4)
    );
    return permutationNumbers[number.toString().padStart(5, "0").slice(0, 4)] ?? false;
  },
  [address.SHUFFLE_3_GAME.toLowerCase()]: (number: BigNumberish, answer: BigNumberish) => {
    const permutationNumbers = getAllPermutationNumbersMap(
      answer.toString().padStart(5, "0").slice(0, 3)
    );
    return permutationNumbers[number.toString().padStart(5, "0").slice(0, 3)] ?? false;
  },
};

export function isNumberWinning(number: BigNumberish, answer: BigNumberish, game: string) {
  return gameWinningLogic[game.toLowerCase()]?.(number, answer) ?? false;
}

export function findWinningPrize(
  playInfo: PlayInfo,
  answer: BigNumberish,
  includingClaimed = true
) {
  let highestPrize = 0;
  let highestGame: WinningGame | undefined;

  if (playInfo.claimed && !includingClaimed) {
    return { win: false, prize: 0, game: undefined };
  }

  for (const game of jackpotGames) {
    if (isNumberWinning(playInfo.number, answer, game)) {
      const newPrize = Number(
        utils.formatUnits(
          playInfo.amount.mul(config.jackpotPayout[game]).div(100),
          config.stablecoinDecimals
        )
      );
      if (newPrize > highestPrize) {
        highestPrize = newPrize;
        highestGame = {
          address: game,
          title: JACKPOT_GAME_TITLES[game],
          highlightCount: JACKPOT_GAMES_NUMBER_HIGHLIGHT[game],
        };
      }
    }
  }

  return { win: highestPrize > 0, prize: highestPrize, game: highestGame };
}
